export default {
  GET_WEBHOOKS_REQUEST: 'GET_WEBHOOKS_REQUEST',
  GET_WEBHOOKS_SUCCESS: 'GET_WEBHOOKS_SUCCESS',
  GET_WEBHOOKS_FAIL: 'GET_WEBHOOKS_FAIL',

  GET_WEBHOOK_EVENTS_REQUEST: 'GET_WEBHOOK_EVENTS_REQUEST',
  GET_WEBHOOK_EVENTS_SUCCESS: 'GET_WEBHOOK_EVENTS_SUCCESS',
  GET_WEBHOOK_EVENTS_FAIL: 'GET_WEBHOOK_EVENTS_FAIL',

  GET_WEBHOOK_REQUEST: 'GET_WEBHOOK_REQUEST',
  GET_WEBHOOK_SUCCESS: 'GET_WEBHOOK_SUCCESS',
  GET_WEBHOOK_FAIL: 'GET_WEBHOOK_FAIL',

  UPDATE_WEBHOOK_REQUEST: 'UPDATE_WEBHOOK_REQUEST',
  UPDATE_WEBHOOK_SUCCESS: 'UPDATE_WEBHOOK_SUCCESS',
  UPDATE_WEBHOOK_FAIL: 'UPDATE_WEBHOOK_FAIL',

  CREATE_WEBHOOK_REQUEST: 'CREATE_WEBHOOK_REQUEST',
  CREATE_WEBHOOK_SUCCESS: 'CREATE_WEBHOOK_SUCCESS',
  CREATE_WEBHOOK_FAIL: 'CREATE_WEBHOOK_FAIL',

  DELETE_WEBHOOK_REQUEST: 'DELETE_WEBHOOK_REQUEST',
  DELETE_WEBHOOK_SUCCESS: 'DELETE_WEBHOOK_SUCCESS',
  DELETE_WEBHOOK_FAIL: 'DELETE_WEBHOOK_FAIL',
};
