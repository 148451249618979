import moment from 'moment';

export function objectToArray(obj) {
  if (!obj) return [];
  return Object.keys(obj).map(key => {
    return obj[key];
  });
}

export function arrayToObject(arr) {
  if (!arr) return {};

  let obj = {};
  for (let i = 0; i < arr.length; i++) {
    obj[arr[i].id] = arr[i];
  }

  return obj;
}

export const toObject = (arr, key) => arr.reduce((a, b) => ({ ...a, [b[key]]: b }), {});

export function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export function removeItem(array, id) {
  return array.filter(item => item.id !== id);
}

export function insertItem(array, item) {
  let newArray = array.slice();
  newArray.splice(0, 0, item);
  return newArray;
}

export function updateObjectInArray(array, obj) {
  return array.map(item => {
    if (item.id !== obj.id) {
      return item;
    }

    return {
      ...item,
      ...obj,
    };
  });
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function trimProperty(form) {
  return Object.keys(form).reduce((newForm, key) => {
    const value = form[key];
    if (typeof value === 'string') {
      return { ...newForm, [key]: value.trim() };
    }
    return { ...newForm, [key]: value };
  }, {});
}

export function convertFloors(floors) {
  const arrays = floors
    .split(',')
    .map(floor => parseInt(floor))
    .reduce((r, n) => {
      const lastSubArray = r[r.length - 1];

      if (!lastSubArray || lastSubArray[lastSubArray.length - 1] !== n - 1) {
        r.push([]);
      }

      r[r.length - 1].push(n);

      return r;
    }, []);

  const newArr = arrays.flatMap(arr => {
    if (arr.length < 3) {
      return arr.map(item => item);
    } else {
      return `${arr[0]}-${arr[arr.length - 1]}`;
    }
  });

  return newArr.join(', ');
}

export function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

export function disabledTime(current) {
  return {
    disabledHours: () => {
      if (current > moment().endOf('day')) return [];
      return range(0, 24).filter(hour => hour < parseInt(moment().format('H')));
    },
    disabledMinutes: () => {
      if (current > moment()) return [];
      return range(0, 60).filter(min => min < parseInt(moment().format('m')));
    },
  };
}

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function validatePhone(phone) {
  // const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  const re = /^[0-9]+$/;
  return re.test(phone);
}

export function validateTax(tax) {
  const re1 = /^[0-9]{10}$/;
  const re2 = /^[0-9]{10}?[-\s\.]?[0-9]{3}$/;
  return re1.test(tax) || re2.test(tax);
}

export function convertSize(size) {
  if (!size) return '';
  const mb = size / (1024 * 1024);
  const kb = size / 1024;
  if (mb < 1) {
    return parseFloat(kb).toFixed(2) + ' KB';
  }
  return parseFloat(mb).toFixed(2) + ' MB';
}

export function isNumeric(str) {
  if (typeof str != 'string') return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export function download(data, filename) {
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  link.parentNode.removeChild(link)
}

function xoa_dau(str) {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  str = str.replace(/đ/g, 'd')
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
  str = str.replace(/Đ/g, 'D')
  return str
}

export function getExcelFileName(folder_name) {
  let filename = `${xoa_dau(folder_name).split(' ').join('_')}_${moment().format('YYYYMMDD')}.xlsx`
  return filename
}