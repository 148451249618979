import { combineReducers } from 'redux';
import { AccountTypes, AuthTypes } from '../../action_types';

function user(state = {}, action) {
  switch (action.type) {
    case AuthTypes.LOGIN_SUCCESS:
    case AuthTypes.GET_ME_SUCCESS:
    case AuthTypes.UPDATE_ME_SUCCESS:
      return { ...state, ...action.data };
    case AccountTypes.ACCEPT_INVITE_SUCCESS:
      return action.data.user;
    default:
      return state;
  }
}

function existed_user(state = false, action) {
  switch (action.type) {
    case AuthTypes.CHECK_EMAIL_SUCCESS:
      return action.data?.existed_user;
    default:
      return state;
  }
}

export default combineReducers({
  user,
  existed_user,
});
