export default {
  GET_FOLDERS_REQUEST: 'GET_FOLDERS_REQUEST',
  GET_FOLDERS_SUCCESS: 'GET_FOLDERS_SUCCESS',
  GET_FOLDERS_FAIL: 'GET_FOLDERS_FAIL',

  GET_FAVORITE_FOLDERS_REQUEST: 'GET_FAVORITE_FOLDERS_REQUEST',
  GET_FAVORITE_FOLDERS_SUCCESS: 'GET_FAVORITE_FOLDERS_SUCCESS',
  GET_FAVORITE_FOLDERS_FAIL: 'GET_FAVORITE_FOLDERS_FAIL',

  SEARCH_FOLDERS_REQUEST: 'SEARCH_FOLDERS_REQUEST',
  SEARCH_FOLDERS_SUCCESS: 'SEARCH_FOLDERS_SUCCESS',
  SEARCH_FOLDERS_FAIL: 'SEARCH_FOLDERS_FAIL',

  GET_FOLDER_REQUEST: 'GET_FOLDER_REQUEST',
  GET_FOLDER_SUCCESS: 'GET_FOLDER_SUCCESS',
  GET_FOLDER_FAIL: 'GET_FOLDER_FAIL',

  UPDATE_FOLDER_REQUEST: 'UPDATE_FOLDER_REQUEST',
  UPDATE_FOLDER_SUCCESS: 'UPDATE_FOLDER_SUCCESS',
  UPDATE_FOLDER_FAIL: 'UPDATE_FOLDER_FAIL',

  CREATE_FOLDER_REQUEST: 'CREATE_FOLDER_REQUEST',
  CREATE_FOLDER_SUCCESS: 'CREATE_FOLDER_SUCCESS',
  CREATE_FOLDER_FAIL: 'CREATE_FOLDER_FAIL',

  DELETE_FOLDER_REQUEST: 'DELETE_FOLDER_REQUEST',
  DELETE_FOLDER_SUCCESS: 'DELETE_FOLDER_SUCCESS',
  DELETE_FOLDER_FAIL: 'DELETE_FOLDER_FAIL',

  SET_CURRENT_FOLDER: 'SET_CURRENT_FOLDER',

  ASSIGN_FOLDER_REQUEST: 'ASSIGN_FOLDER_REQUEST',
  ASSIGN_FOLDER_SUCCESS: 'ASSIGN_FOLDER_SUCCESS',
  ASSIGN_FOLDER_FAIL: 'ASSIGN_FOLDER_FAIL',

  UPDATE_FOLDER_WEBHOOK_REQUEST: 'UPDATE_FOLDER_WEBHOOK_REQUEST',
  UPDATE_FOLDER_WEBHOOK_SUCCESS: 'UPDATE_FOLDER_WEBHOOK_SUCCESS',
  UPDATE_FOLDER_WEBHOOK_FAIL: 'UPDATE_FOLDER_WEBHOOK_FAIL',



  GET_FOLDER_DOCUMENT_TEMPLATES_REQUESTS: 'GET_FOLDER_DOCUMENT_TEMPLATES_REQUESTS',
  GET_FOLDER_DOCUMENT_TEMPLATES_SUCCESS: 'GET_FOLDER_DOCUMENT_TEMPLATES_SUCCESS',
  GET_FOLDER_DOCUMENT_TEMPLATES_FAIL: 'GET_FOLDER_DOCUMENT_TEMPLATES_FAIL',

  UPDATE_SERVICE_LABEL_REQUEST: 'UPDATE_SERVICE_LABEL_REQUEST',
  UPDATE_SERVICE_LABEL_SUCCESS: 'UPDATE_SERVICE_LABEL_SUCCESS',
  UPDATE_SERVICE_LABEL_FAIL: 'UPDATE_SERVICE_LABEL_FAIL',

  UPDATE_FOLDER_SUBMISSION_FIELD_REQUEST: 'UPDATE_FOLDER_SUBMISSION_FIELD_REQUEST',
  UPDATE_FOLDER_SUBMISSION_FIELD_SUCCESS: 'UPDATE_FOLDER_SUBMISSION_FIELD_SUCCESS',
  UPDATE_FOLDER_SUBMISSION_FIELD_FAIL: 'UPDATE_FOLDER_SUBMISSION_FIELD_FAIL',

  CREATE_FOLDER_SUBMISSION_FIELD_REQUEST: 'CREATE_FOLDER_SUBMISSION_FIELD_REQUEST',
  CREATE_FOLDER_SUBMISSION_FIELD_SUCCESS: 'CREATE_FOLDER_SUBMISSION_FIELD_SUCCESS',
  CREATE_FOLDER_SUBMISSION_FIELD_FAIL: 'CREATE_FOLDER_SUBMISSION_FIELD_FAIL',

  DELETE_FOLDER_SUBMISSION_FIELD_REQUEST: 'DELETE_FOLDER_SUBMISSION_FIELD_REQUEST',
  DELETE_FOLDER_SUBMISSION_FIELD_SUCCESS: 'DELETE_FOLDER_SUBMISSION_FIELD_SUCCESS',
  DELETE_FOLDER_SUBMISSION_FIELD_FAIL: 'DELETE_FOLDER_SUBMISSION_FIELD_FAIL',

  SORT_LABELS_REQUEST: 'SORT_LABELS_REQUEST',
  SORT_LABELS_SUCCESS: 'SORT_LABELS_SUCCESS',
  SORT_LABELS_FAIL: 'SORT_LABELS_FAIL',

  SORT_FIELDS_REQUEST: 'SORT_FIELDS_REQUEST',
  SORT_FIELDS_SUCCESS: 'SORT_FIELDS_SUCCESS',
  SORT_FIELDS_FAIL: 'SORT_FIELDS_FAIL',

  CLEAR_LABELS: 'CLEAR_LABELS',
  CLEAR_FIELDS: 'CLEAR_FIELDS',
};
