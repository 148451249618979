export default {
  GET_PAYMENTS_REQUEST: 'GET_PAYMENTS_REQUEST',
  GET_PAYMENTS_SUCCESS: 'GET_PAYMENTS_SUCCESS',
  GET_PAYMENTS_FAIL: 'GET_PAYMENTS_FAIL',

  GET_PAYMENT_REQUEST: 'GET_PAYMENT_REQUEST',
  GET_PAYMENT_SUCCESS: 'GET_PAYMENT_SUCCESS',
  GET_PAYMENT_FAIL: 'GET_PAYMENT_FAIL',

  CREATE_PAYMENT_REQUEST: 'CREATE_PAYMENT_REQUEST',
  CREATE_PAYMENT_SUCCESS: 'CREATE_PAYMENT_SUCCESS',
  CREATE_PAYMENT_FAIL: 'CREATE_PAYMENT_FAIL',

  UPDATE_PAYMENT_REQUEST: 'UPDATE_PAYMENT_REQUEST',
  UPDATE_PAYMENT_SUCCESS: 'UPDATE_PAYMENT_SUCCESS',
  UPDATE_PAYMENT_FAIL: 'UPDATE_PAYMENT_FAIL',

  DELETE_PAYMENT_REQUEST: 'DELETE_PAYMENT_REQUEST',
  DELETE_PAYMENT_SUCCESS: 'DELETE_PAYMENT_SUCCESS',
  DELETE_PAYMENT_FAIL: 'DELETE_PAYMENT_FAIL',

  GET_NEWEST_PAYMENT_REQUEST: 'GET_NEWEST_PAYMENT_REQUEST',
  GET_NEWEST_PAYMENT_SUCCESS: 'GET_NEWEST_PAYMENT_SUCCESS',
  GET_NEWEST_PAYMENT_FAIL: 'GET_NEWEST_PAYMENT_FAIL',
};
