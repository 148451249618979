import { combineReducers } from 'redux';
import { ApiKeyTypes } from '../../action_types';

import { handleRequest, initialRequestState } from './helpers';

function getApiKeys(state = initialRequestState(), action) {
  return handleRequest(
    ApiKeyTypes.GET_API_KEYS_REQUEST,
    ApiKeyTypes.GET_API_KEYS_SUCCESS,
    ApiKeyTypes.GET_API_KEYS_FAIL,
    state,
    action
  );
}

function getApiKey(state = initialRequestState(), action) {
  return handleRequest(
    ApiKeyTypes.GET_API_KEY_REQUEST,
    ApiKeyTypes.GET_API_KEY_SUCCESS,
    ApiKeyTypes.GET_API_KEY_FAIL,
    state,
    action
  );
}

function createApiKey(state = initialRequestState(), action) {
  return handleRequest(
    ApiKeyTypes.CREATE_API_KEY_REQUEST,
    ApiKeyTypes.CREATE_API_KEY_SUCCESS,
    ApiKeyTypes.CREATE_API_KEY_FAIL,
    state,
    action
  );
}

function updateApiKey(state = initialRequestState(), action) {
  return handleRequest(
    ApiKeyTypes.UPDATE_API_KEY_REQUEST,
    ApiKeyTypes.UPDATE_API_KEY_SUCCESS,
    ApiKeyTypes.UPDATE_API_KEY_FAIL,
    state,
    action
  );
}

function deleteApiKey(state = initialRequestState(), action) {
  return handleRequest(
    ApiKeyTypes.DELETE_API_KEY_REQUEST,
    ApiKeyTypes.DELETE_API_KEY_SUCCESS,
    ApiKeyTypes.DELETE_API_KEY_FAIL,
    state,
    action
  );
}

export default combineReducers({
  getApiKeys,
  getApiKey,
  createApiKey,
  updateApiKey,
  deleteApiKey,
});
