import { combineReducers } from 'redux';
import { FileTypes } from '../../action_types';
import { arrayToObject, removeItem, insertItem, updateObjectInArray } from 'src/utils/helpers';
import { toObject } from 'src/utils/helpers';

function all(state = {}, action) {
  switch (action.type) {
    case FileTypes.GET_FILES_SUCCESS:
      return { ...state, ...arrayToObject(action.data.results) };
    case FileTypes.GET_FILE_SUCCESS:
    case FileTypes.VALIDATE_FILE_SUCCESS:
    case FileTypes.VERIFY_FILE_SUCCESS:
    case FileTypes.REJECT_FILE_SUCCESS:
    case FileTypes.CREATE_FILE_SUCCESS:
    case FileTypes.UPDATE_FILE_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      };
    case FileTypes.ARCHIVE_FILE_SUCCESS:
      const { [action.id]: _, ...newState } = state;
      return newState;
    default:
      return state;
  }
}

function ui(state = [], action) {
  switch (action.type) {
    case FileTypes.GET_FILES_SUCCESS:
      return action.data.results;
    case FileTypes.CREATE_FILE_SUCCESS:
      return insertItem(state, action.data);
    case FileTypes.UPDATE_FILE_SUCCESS:
    case FileTypes.VALIDATE_FILE_SUCCESS:
    case FileTypes.VERIFY_FILE_SUCCESS:
    case FileTypes.REJECT_FILE_SUCCESS:
      return updateObjectInArray(state, action.data);
    case FileTypes.ARCHIVE_FILE_SUCCESS:
      return removeItem(state, action.id);
    case FileTypes.CLEAR_FILES:
      return [];
    default:
      return state;
  }
}

function meta(state = {}, action) {
  switch (action.type) {
    case FileTypes.GET_FILES_SUCCESS:
      const { results, ...rest } = action.data;
      return { ...state, ...rest };
    case FileTypes.ARCHIVE_FILE_SUCCESS: {
      const { count } = state;
      return { ...state, count: count - 1 };
    }
    default:
      return state;
  }
}

function images(state = {}, action) {
  switch (action.type) {
    case FileTypes.UPLOAD_FILE_IMAGE_SUCCESS:
      return { ...state, [action.id]: action.image };
    case FileTypes.GET_FILE_IMAGE_SUCCESS:
    case FileTypes.GET_PAGE_IMAGE_SUCCESS:
      return { ...state, [action.id]: action.data };
    default:
      return state;
  }
}

function images_original(state = {}, action) {
  switch (action.type) {
    case FileTypes.GET_FILE_IMAGE_ORIGIN_SUCCESS:
    case FileTypes.GET_PAGE_IMAGE_ORIGIN_SUCCESS:
      return { ...state, [action.id]: action.data };
    default:
      return state;
  }
}

function data(state = {}, action) {
  switch (action.type) {
    case FileTypes.GET_FILE_DATA_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      };
    case FileTypes.CLEAR_FILE_DATA:
      const { [action.id]: _, ...newState } = state;
      return newState;
    default:
      return state;
  }
}

function data_json(state = {}, action) {
  switch (action.type) {
    case FileTypes.GET_FILE_DATA_JSON_SUCCESS:
      return {
        ...state,
        [action.file_id]: action.data,
      };
    default:
      return state;
  }
}

function data_lookup(state = {}, action) {
  switch (action.type) {
    case FileTypes.GET_FILE_DATA_SUCCESS: {
      let obj = {};
      action.data.page_set.forEach(page => {
        if (state[page.id] !== 1) {
          obj[page.id] = page.lookup_result;
        }
      });
      return {
        ...state,
        ...obj,
      };
    }
    case FileTypes.LOOKUP_FILE_REQUEST: {
      return {
        ...state,
        [action.id]: 1,
      };
    }
    case FileTypes.LOOKUP_FILE_SUCCESS: {
      return {
        ...state,
        [action.id]: action.data,
      };
    }
    case FileTypes.LOOKUP_FILE_FAIL: {
      return {
        ...state,
        [action.id]: 0,
      };
    }
    default:
      return state;
  }
}

function filesNumber(state = {}, action) {
  switch (action.type) {
    case FileTypes.GET_FILES_NUMBER_SUCCESS:
      return { ...state, ...toObject(action.data, 'status') };
    default:
      return state;
  }
}

function filesByStatus(state = [], action) {
  switch (action.type) {
    case FileTypes.GET_FILES_BY_STATUS_SUCCESS:
      return action.data.results;
    case FileTypes.GET_FILE_DATA_SUCCESS:
      return updateObjectInArray(state, action.data);
    // case FileTypes.VALIDATE_FILE_SUCCESS:
    // case FileTypes.VERIFY_FILE_SUCCESS:
    // case FileTypes.REJECT_FILE_SUCCESS:
    //   return removeItem(state, action.data.id);
    case FileTypes.REMOVE_FILE:
      return removeItem(state, action.id);
    case FileTypes.CLEAR_FILES_BY_STATUS:
      return [];
    default:
      return state;
  }
}

function screenshot(state = {}, action) {
  switch (action.type) {
    case FileTypes.GET_SCREENSHOT_SUCCESS:
      return { ...state, [action.id]: action.data };
    default:
      return state;
  }
}

export default combineReducers({
  all,
  ui,
  meta,
  images,
  images_original,
  data,
  filesNumber,
  filesByStatus,
  data_json,
  screenshot,
  data_lookup,
});
