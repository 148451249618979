import { combineReducers } from 'redux';
import { OrgTypes, TemplateTypes } from '../../action_types';
import { arrayToObject, removeItem, insertItem, updateObjectInArray } from 'src/utils/helpers';

function all(state = {}, action) {
  switch (action.type) {
    case TemplateTypes.GET_TEMPLATES_SUCCESS:
      return { ...state, ...arrayToObject(action.data.results) };
    case TemplateTypes.GET_TEMPLATE_SUCCESS:
    case TemplateTypes.CREATE_TEMPLATE_SUCCESS:
    case TemplateTypes.UPDATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      };
    case TemplateTypes.DELETE_TEMPLATE_SUCCESS:
      const { [action.id]: _, ...newState } = state;
      return newState;
    default:
      return state;
  }
}

function templates(state = [], action) {
  switch (action.type) {
    case TemplateTypes.GET_TEMPLATES_SUCCESS:
      return action.data.results;
    case TemplateTypes.CREATE_TEMPLATE_SUCCESS:
      return insertItem(state, action.data);
    case TemplateTypes.UPDATE_TEMPLATE_SUCCESS:
      return updateObjectInArray(state, action.data);
    case TemplateTypes.DELETE_TEMPLATE_SUCCESS:
      return removeItem(state, action.id);
    default:
      return state;
  }
}

function template(state = {}, action) {
  switch (action.type) {
    case TemplateTypes.CREATE_TEMPLATE_SUCCESS:
    case TemplateTypes.GET_TEMPLATE_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

function files(state = {}, action) {
  switch (action.type) {
    case TemplateTypes.GET_FILE_TEMPLATE_SUCCESS:
      return {
        ...state,
        [action.id]: action.data,
      };
    default:
      return state;
  }
}

function templatesDefault(state = [], action) {
  switch (action.type) {
    case TemplateTypes.GET_TEMPLATES_DEFAULT_SUCCESS:
      return action.data;
    case TemplateTypes.CREATE_TEMPLATE_SUCCESS:
      return insertItem(state, action.data);
    case TemplateTypes.UPDATE_TEMPLATE_SUCCESS:
    case OrgTypes.UPDATE_DOCUMENT_TYPE_SUCCESS:
      return updateObjectInArray(state, action.data);
    case TemplateTypes.DELETE_TEMPLATE_SUCCESS:
      return removeItem(state, action.id);
    default:
      return state;
  }
}

function labels(state = {}, action) {
  switch (action.type) {
    case TemplateTypes.GET_TEMPLATE_LABEL_SUCCESS:
    case TemplateTypes.CREATE_TEMPLATE_LABEL_SUCCESS:
    case TemplateTypes.UPDATE_TEMPLATE_LABEL_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      };
    case TemplateTypes.DELETE_TEMPLATE_LABEL_SUCCESS:
      const { [action.id]: _, ...newState } = state;
      return newState;
    default:
      return state;
  }
}

export default combineReducers({
  all,
  templates,
  template,
  templatesDefault,
  labels,
  files,
});
