export default {
  GET_ORGS_REQUEST: 'GET_ORGS_REQUEST',
  GET_ORGS_SUCCESS: 'GET_ORGS_SUCCESS',
  GET_ORGS_FAIL: 'GET_ORGS_FAIL',

  GET_ORG_REQUEST: 'GET_ORG_REQUEST',
  GET_ORG_SUCCESS: 'GET_ORG_SUCCESS',
  GET_ORG_FAIL: 'GET_ORG_FAIL',

  UPDATE_ORG_REQUEST: 'UPDATE_ORG_REQUEST',
  UPDATE_ORG_SUCCESS: 'UPDATE_ORG_SUCCESS',
  UPDATE_ORG_FAIL: 'UPDATE_ORG_FAIL',

  CREATE_ORG_REQUEST: 'CREATE_ORG_REQUEST',
  CREATE_ORG_SUCCESS: 'CREATE_ORG_SUCCESS',
  CREATE_ORG_FAIL: 'CREATE_ORG_FAIL',

  DELETE_ORG_REQUEST: 'DELETE_ORG_REQUEST',
  DELETE_ORG_SUCCESS: 'DELETE_ORG_SUCCESS',
  DELETE_ORG_FAIL: 'DELETE_ORG_FAIL',

  CREATE_DOCUMENT_TYPE_REQUEST: 'CREATE_DOCUMENT_TYPE_REQUEST',
  CREATE_DOCUMENT_TYPE_SUCCESS: 'CREATE_DOCUMENT_TYPE_SUCCESS',
  CREATE_DOCUMENT_TYPE_FAIL: 'CREATE_DOCUMENT_TYPE_FAIL',

  GET_DOCUMENT_ANALYTICS_REQUEST: 'GET_DOCUMENT_ANALYTICS_REQUEST',
  GET_DOCUMENT_ANALYTICS_SUCCESS: 'GET_DOCUMENT_ANALYTICS_SUCCESS',
  GET_DOCUMENT_ANALYTICS_FAIL: 'GET_DOCUMENT_ANALYTICS_FAIL',

  GET_ORG_PROFILE_IMAGE_REQUEST: 'GET_ORG_PROFILE_IMAGE_REQUEST',
  GET_ORG_PROFILE_IMAGE_SUCCESS: 'GET_ORG_PROFILE_IMAGE_SUCCESS',
  GET_ORG_PROFILE_IMAGE_FAIL: 'GET_ORG_PROFILE_IMAGE_FAIL',

  UPLOAD_ORG_PROFILE_IMAGE_REQUEST: 'UPLOAD_ORG_PROFILE_IMAGE_REQUEST',
  UPLOAD_ORG_PROFILE_IMAGE_SUCCESS: 'UPLOAD_ORG_PROFILE_IMAGE_SUCCESS',
  UPLOAD_ORG_PROFILE_IMAGE_FAIL: 'UPLOAD_ORG_PROFILE_IMAGE_FAIL',

  LEAVE_ORG_REQUEST: 'LEAVE_ORG_REQUEST',
  LEAVE_ORG_SUCCESS: 'LEAVE_ORG_SUCCESS',
  LEAVE_ORG_FAIL: 'LEAVE_ORG_FAIL',

  GET_CREDIT_BALANCE_REQUEST: 'GET_CREDIT_BALANCE_REQUEST',
  GET_CREDIT_BALANCE_SUCCESS: 'GET_CREDIT_BALANCE_SUCCESS',
  GET_CREDIT_BALANCE_FAIL: 'GET_CREDIT_BALANCE_FAIL',
};
