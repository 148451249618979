export default {
  UPLOAD_FILES_REQUEST: 'UPLOAD_FILES_REQUEST',
  UPLOAD_FILES_SUCCESS: 'UPLOAD_FILES_SUCCESS',
  UPLOAD_FILES_FAIL: 'UPLOAD_FILES_FAIL',

  GET_FILES_REQUEST: 'GET_FILES_REQUEST',
  GET_FILES_SUCCESS: 'GET_FILES_SUCCESS',
  GET_FILES_FAIL: 'GET_FILES_FAIL',

  GET_FILES_BY_STATUS_REQUEST: 'GET_FILES_BY_STATUS_REQUEST',
  GET_FILES_BY_STATUS_SUCCESS: 'GET_FILES_BY_STATUS_SUCCESS',
  GET_FILES_BY_STATUS_FAIL: 'GET_FILES_BY_STATUS_FAIL',

  GET_FILE_REQUEST: 'GET_FILE_REQUEST',
  GET_FILE_SUCCESS: 'GET_FILE_SUCCESS',
  GET_FILE_FAIL: 'GET_FILE_FAIL',

  GET_FILES_NUMBER_REQUEST: 'GET_FILES_NUMBER_REQUEST',
  GET_FILES_NUMBER_SUCCESS: 'GET_FILES_NUMBER_SUCCESS',
  GET_FILES_NUMBER_FAIL: 'GET_FILES_NUMBER_FAIL',

  ARCHIVE_FILE_REQUEST: 'ARCHIVE_FILE_REQUEST',
  ARCHIVE_FILE_SUCCESS: 'ARCHIVE_FILE_SUCCESS',
  ARCHIVE_FILE_FAIL: 'ARCHIVE_FILE_FAIL',

  GET_FILE_IMAGE_REQUEST: 'GET_FILE_IMAGE_REQUEST',
  GET_FILE_IMAGE_SUCCESS: 'GET_FILE_IMAGE_SUCCESS',
  GET_FILE_IMAGE_FAIL: 'GET_FILE_IMAGE_FAIL',

  GET_FILE_IMAGE_ORIGIN_REQUEST: 'GET_FILE_IMAGE_ORIGIN_REQUEST',
  GET_FILE_IMAGE_ORIGIN_SUCCESS: 'GET_FILE_IMAGE_ORIGIN_SUCCESS',
  GET_FILE_IMAGE_ORIGIN_FAIL: 'GET_FILE_IMAGE_ORIGIN_FAIL',

  GET_PAGE_IMAGE_REQUEST: 'GET_PAGE_IMAGE_REQUEST',
  GET_PAGE_IMAGE_SUCCESS: 'GET_PAGE_IMAGE_SUCCESS',
  GET_PAGE_IMAGE_FAIL: 'GET_PAGE_IMAGE_FAIL',

  GET_PAGE_IMAGE_ORIGIN_REQUEST: 'GET_PAGE_IMAGE_ORIGIN_REQUEST',
  GET_PAGE_IMAGE_ORIGIN_SUCCESS: 'GET_PAGE_IMAGE_ORIGIN_SUCCESS',
  GET_PAGE_IMAGE_ORIGIN_FAIL: 'GET_PAGE_IMAGE_ORIGIN_FAIL',

  GET_FILE_DATA_REQUEST: 'GET_FILE_DATA_REQUEST',
  GET_FILE_DATA_SUCCESS: 'GET_FILE_DATA_SUCCESS',
  GET_FILE_DATA_FAIL: 'GET_FILE_DATA_FAIL',

  GET_FILE_DATA_JSON_REQUEST: 'GET_FILE_DATA_JSON_REQUEST',
  GET_FILE_DATA_JSON_SUCCESS: 'GET_FILE_DATA_JSON_SUCCESS',
  GET_FILE_DATA_JSON_FAIL: 'GET_FILE_DATA_JSON_FAIL',

  VALIDATE_FILE_REQUEST: 'VALIDATE_FILE_REQUEST',
  VALIDATE_FILE_SUCCESS: 'VALIDATE_FILE_SUCCESS',
  VALIDATE_FILE_FAIL: 'VALIDATE_FILE_FAIL',

  VALIDATE_FILES_REQUEST: 'VALIDATE_FILES_REQUEST',
  VALIDATE_FILES_SUCCESS: 'VALIDATE_FILES_SUCCESS',
  VALIDATE_FILES_FAIL: 'VALIDATE_FILES_FAIL',

  VERIFY_FILES_REQUEST: 'VERIFY_FILES_REQUEST',
  VERIFY_FILES_SUCCESS: 'VERIFY_FILES_SUCCESS',
  VERIFY_FILES_FAIL: 'VERIFY_FILES_FAIL',

  VERIFY_FILE_REQUEST: 'VERIFY_FILE_REQUEST',
  VERIFY_FILE_SUCCESS: 'VERIFY_FILE_SUCCESS',
  VERIFY_FILE_FAIL: 'VERIFY_FILE_FAIL',

  REJECT_FILE_REQUEST: 'REJECT_FILE_REQUEST',
  REJECT_FILE_SUCCESS: 'REJECT_FILE_SUCCESS',
  REJECT_FILE_FAIL: 'REJECT_FILE_FAIL',

  RETRY_OCR_FILES_REQUEST: 'RETRY_OCR_FILES_REQUEST',
  RETRY_OCR_FILES_SUCCESS: 'RETRY_OCR_FILES_SUCCESS',
  RETRY_OCR_FILES_FAIL: 'RETRY_OCR_FILES_FAIL',

  DELETE_FILES_REQUEST: 'DELETE_FILES_REQUEST',
  DELETE_FILES_SUCCESS: 'DELETE_FILES_SUCCESS',
  DELETE_FILES_FAIL: 'DELETE_FILES_FAIL',

  EXPORT_FILES_REQUEST: 'EXPORT_FILES_REQUEST',
  EXPORT_FILES_SUCCESS: 'EXPORT_FILES_SUCCESS',
  EXPORT_FILES_FAIL: 'EXPORT_FILES_FAIL',

  DOWNLOAD_FILE_REQUEST: 'DOWNLOAD_FILE_REQUEST',
  DOWNLOAD_FILE_SUCCESS: 'DOWNLOAD_FILE_SUCCESS',
  DOWNLOAD_FILE_FAIL: 'DOWNLOAD_FILE_FAIL',

  DOWNLOAD_FILE_ORIGIN_REQUEST: 'DOWNLOAD_FILE_ORIGIN_REQUEST',
  DOWNLOAD_FILE_ORIGIN_SUCCESS: 'DOWNLOAD_FILE_ORIGIN_SUCCESS',
  DOWNLOAD_FILE_ORIGIN_FAIL: 'DOWNLOAD_FILE_ORIGIN_FAIL',

  GET_SCREENSHOT_REQUEST: 'GET_SCREENSHOT_REQUEST',
  GET_SCREENSHOT_SUCCESS: 'GET_SCREENSHOT_SUCCESS',
  GET_SCREENSHOT_FAIL: 'GET_SCREENSHOT_FAIL',

  CLEAR_FILES: 'CLEAR_FILES',

  CLEAR_FILES_BY_STATUS: 'CLEAR_FILES_BY_STATUS',

  CLEAR_FILE_DATA: 'CLEAR_FILE_DATA',

  REMOVE_FILE: 'REMOVE_FILE',

  LOOKUP_FILE_REQUEST: 'LOOKUP_FILE_REQUEST',
  LOOKUP_FILE_SUCCESS: 'LOOKUP_FILE_SUCCESS',
  LOOKUP_FILE_FAIL: 'LOOKUP_FILE_FAIL',
};
