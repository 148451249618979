import { combineReducers } from 'redux';
import { StatisticsTypes } from '../../action_types';
import { arrayToObject, removeItem, insertItem, updateObjectInArray } from 'src/utils/helpers';
import moment from 'moment';

function submission_summary(state = {}, action) {
  switch (action.type) {
    case StatisticsTypes.GET_SUBMISSIONS_SUMMARY_SUCCESS:
      return action.data;

    default:
      return state;
  }
}

function total(state = {}, action) {
  switch (action.type) {
    case StatisticsTypes.GET_STATISTICS_BY_DAY_SUCCESS:
    case StatisticsTypes.GET_STATISTICS_BY_WEEK_SUCCESS:
    case StatisticsTypes.GET_STATISTICS_BY_MONTH_SUCCESS:
      const { uploaded, verified, exported } = action.data;

      return {
        uploaded,
        verified,
        exported,
      };

    default:
      return state;
  }
}

function submission_count(state = { data: [], max: 0 }, action) {
  switch (action.type) {
    case StatisticsTypes.GET_STATISTICS_BY_DAY_SUCCESS:
    case StatisticsTypes.GET_STATISTICS_BY_WEEK_SUCCESS:
    case StatisticsTypes.GET_STATISTICS_BY_MONTH_SUCCESS:
      let data = [];
      let max = 0;

      data = action.data.agg.map(dt => {
        const { date, month, total, total_verified, total_exported } = dt;
        if (total + total_verified + total_exported > max) {
          max = total + total_verified + total_exported;
        }
        return {
          date: date || month,
          total,
          total_verified,
          total_exported,
        };
      });
      return {
        data,
        max,
      };
    default:
      return state;
  }
}

function validation_time(state = { data: [], max: 0 }, action) {
  switch (action.type) {
    case StatisticsTypes.GET_STATISTICS_BY_DAY_SUCCESS:
    case StatisticsTypes.GET_STATISTICS_BY_WEEK_SUCCESS:
    case StatisticsTypes.GET_STATISTICS_BY_MONTH_SUCCESS:
      let data = [];
      let max = 0;

      data = action.data.agg.map(dt => {
        const { date, month, avg_validate_time } = dt;
        const value = parseFloat(parseFloat(avg_validate_time / 60).toFixed(2));
        if (value > max) {
          max = value;
        }
        return {
          date: date || month,
          time: value,
        };
      });
      return {
        data,
        max,
      };
    default:
      return state;
  }
}

function turnaround_time(state = { data: [], max: 0 }, action) {
  switch (action.type) {
    case StatisticsTypes.GET_STATISTICS_BY_DAY_SUCCESS:
    case StatisticsTypes.GET_STATISTICS_BY_WEEK_SUCCESS:
    case StatisticsTypes.GET_STATISTICS_BY_MONTH_SUCCESS:
      let data = [];
      let max = 0;
      data = action.data.agg.map(dt => {
        const { date, month, avg_turnaround } = dt;
        const value = parseFloat(parseFloat(avg_turnaround / (60 * 60)).toFixed(2));
        if (value > max) {
          max = value;
        }
        return {
          date: date || month,
          time: value,
        };
      });
      return {
        data,
        max,
      };
    default:
      return state;
  }
}

function correction_time(state = {}, action) {
  switch (action.type) {
    case StatisticsTypes.GET_STATISTICS_BY_DAY_SUCCESS:
    case StatisticsTypes.GET_STATISTICS_BY_WEEK_SUCCESS:
    case StatisticsTypes.GET_STATISTICS_BY_MONTH_SUCCESS:
      let newState = {};

      action.data.agg.map(dt => {
        const { date, month, fields, fields_avg_editrate, fields_avg_process_time } = dt;
        const time = date ? moment(date).format('DD-MM-YYYY') : moment(month).format('MM-YYYY');
        newState[time] = {
          date: date || month,
          fields,
          fields_avg_editrate,
          fields_avg_process_time,
        };
      });
      return newState;
    default:
      return state;
  }
}

export default combineReducers({
  submission_summary,
  total,
  submission_count,
  validation_time,
  turnaround_time,
  correction_time,
});
