import { combineReducers } from 'redux';
import { DatapointTypes, FileTypes, SubmissionTypes, BVDocumentTypes, FieldDataTypes } from '../../action_types';
import { arrayToObject } from 'src/utils/helpers';
import { removeItem } from 'src/utils/helpers';

function all(state = {}, action) {
  switch (action.type) {
    case FieldDataTypes.UPDATE_FIELD_DATA_SUCCESS:
      return { ...state, ...arrayToObject(action.data.data_source) };
    case DatapointTypes.SET_DATAPOINTS:
      return { ...state, ...arrayToObject(action.data) };
    case DatapointTypes.GET_DATAPOINTS_SUCCESS:
      return { ...state, ...arrayToObject(action.data.results) };
    case BVDocumentTypes.GET_BV_DOCUMENT_SUCCESS: {
      let newState = { ...state };
      action.data.data_set.map(dt => {
        if (dt.category === 'section') {
          newState = {
            ...newState,
            ...arrayToObject(dt.data_set),
          };
        } else {
          newState = {
            ...newState,
            [dt.id]: dt,
          };
        }
      });
      return newState;
    }
    // case DatapointTypes.UPDATE_DATAPOINT_SUCCESS:
    case DatapointTypes.VALIDATE_DATAPOINT_SUCCESS:
    case DatapointTypes.VERIFY_DATAPOINT_SUCCESS:
    case DatapointTypes.REJECT_DATAPOINT_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      };
    // case DatapointTypes.VALIDATE_DATAPOINT_SUCCESS:
    // case DatapointTypes.VERIFY_DATAPOINT_SUCCESS:
    // case DatapointTypes.REJECT_DATAPOINT_SUCCESS:
    case DatapointTypes.UPDATE_DATAPOINT_SUCCESS:
      return {
        ...state,
        [action.data.id]: { ...state[action.data.id], status: action.data.status },
      };
    case DatapointTypes.GET_DATAPOINT_SUCCESS: {
      if (action.datapoint_type === 'table') {
        const dataTable = action.data.data_set.flatMap(row => row.data_set);
        return {
          ...state,
          [action.data.id]: action.data,
          ...arrayToObject(dataTable),
        };
      }
      return {
        ...state,
        [action.data.id]: action.data,
      };
    }
    case DatapointTypes.ADD_ROW_SUCCESS: {
      const { parent } = action;
      return { ...state, [parent]: { ...state[parent], data_set: [...state[parent].data_set, action.data] } };
    }
    case DatapointTypes.ADD_COLUMN_SUCCESS: {
      const { parent } = action;
      const data_table = [...state[parent].data_set];
      return {
        ...state,
        [parent]: {
          ...state[parent],
          data_set: data_table.map((row, index) => ({ ...row, data_set: [...row.data_set, action.data[index]] })),
        },
      };
    }
    case DatapointTypes.DELETE_ROW_SUCCESS: {
      const { id, parent } = action.data;
      return { ...state, [parent]: { ...state[parent], data_set: removeItem(state[parent].data_set, id) } };
    }
    case DatapointTypes.DELETE_COLUMN_SUCCESS: {
      const { parent, position } = action;
      const data_table = [...state[parent].data_set];
      return {
        ...state,
        [parent]: {
          ...state[parent],
          data_set: data_table.map(row => ({
            ...row,
            data_set: row.data_set.filter((col, index) => index !== position),
          })),
        },
      };
    }
    default:
      return state;
  }
}

function updatedDatapoint(state = {}, action) {
  switch (action.type) {
    case DatapointTypes.VALIDATE_DATAPOINT_SUCCESS:
    case DatapointTypes.VERIFY_DATAPOINT_SUCCESS:
    case DatapointTypes.REJECT_DATAPOINT_SUCCESS:
      return { ...state, ...action.data };
    default:
      return state;
  }
}

function rejectedIds(state = [], action) {
  switch (action.type) {
    case DatapointTypes.VERIFY_DATAPOINT_SUCCESS:
      return [...state].filter(id => id !== action.data.id);
    case DatapointTypes.REJECT_DATAPOINT_SUCCESS:
      return [...state, action.data.id];
    // case FileTypes.REJECT_FILE_SUCCESS:
    case FileTypes.GET_FILE_DATA_SUCCESS:
      return [];
    case BVDocumentTypes.GET_BV_DOCUMENT_SUCCESS:
      return [];
    case DatapointTypes.ADD_REJECTED_DATAPOINT:
      const isExisted = state.includes(action.data);
      return isExisted ? state : [...state, action.data];
    default:
      return state;
  }
}

function values(state = {}, action) {
  switch (action.type) {
    case DatapointTypes.CHANGE_DATAPOINT_VALUE:
      return action.id
        ? {
            ...state,
            [action.id]: action.data,
          }
        : state;
    case BVDocumentTypes.RESET_DOCUMENT_SUCCESS:
    case DatapointTypes.RESET_DATAPOINT_VALUES:
      return {};
    default:
      return state;
  }
}

export default combineReducers({
  all,
  rejectedIds,
  updatedDatapoint,
  values,
});
