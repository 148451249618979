export default {
  GET_TEMPLATES_REQUEST: 'GET_TEMPLATES_REQUEST',
  GET_TEMPLATES_SUCCESS: 'GET_TEMPLATES_SUCCESS',
  GET_TEMPLATES_FAIL: 'GET_TEMPLATES_FAIL',

  GET_TEMPLATE_REQUEST: 'GET_TEMPLATE_REQUEST',
  GET_TEMPLATE_SUCCESS: 'GET_TEMPLATE_SUCCESS',
  GET_TEMPLATE_FAIL: 'GET_TEMPLATE_FAIL',

  UPDATE_TEMPLATE_REQUEST: 'UPDATE_TEMPLATE_REQUEST',
  UPDATE_TEMPLATE_SUCCESS: 'UPDATE_TEMPLATE_SUCCESS',
  UPDATE_TEMPLATE_FAIL: 'UPDATE_TEMPLATE_FAIL',

  CREATE_TEMPLATE_REQUEST: 'CREATE_TEMPLATE_REQUEST',
  CREATE_TEMPLATE_SUCCESS: 'CREATE_TEMPLATE_SUCCESS',
  CREATE_TEMPLATE_FAIL: 'CREATE_TEMPLATE_FAIL',

  DELETE_TEMPLATE_REQUEST: 'DELETE_TEMPLATE_REQUEST',
  DELETE_TEMPLATE_SUCCESS: 'DELETE_TEMPLATE_SUCCESS',
  DELETE_TEMPLATE_FAIL: 'DELETE_TEMPLATE_FAIL',

  GET_TEMPLATES_DEFAULT_REQUEST: 'GET_TEMPLATES_DEFAULT_REQUEST',
  GET_TEMPLATES_DEFAULT_SUCCESS: 'GET_TEMPLATES_DEFAULT_SUCCESS',
  GET_TEMPLATES_DEFAULT_FAIL: 'GET_TEMPLATES_DEFAULT_FAIL',

  GET_TEMPLATE_LABEL_REQUEST: 'GET_TEMPLATE_LABEL_REQUEST',
  GET_TEMPLATE_LABEL_SUCCESS: 'GET_TEMPLATE_LABEL_SUCCESS',
  GET_TEMPLATE_LABEL_FAIL: 'GET_TEMPLATE_LABEL_FAIL',

  UPDATE_TEMPLATE_LABEL_REQUEST: 'UPDATE_TEMPLATE_LABEL_REQUEST',
  UPDATE_TEMPLATE_LABEL_SUCCESS: 'UPDATE_TEMPLATE_LABEL_SUCCESS',
  UPDATE_TEMPLATE_LABEL_FAIL: 'UPDATE_TEMPLATE_LABEL_FAIL',

  CREATE_TEMPLATE_LABEL_REQUEST: 'CREATE_TEMPLATE_LABEL_REQUEST',
  CREATE_TEMPLATE_LABEL_SUCCESS: 'CREATE_TEMPLATE_LABEL_SUCCESS',
  CREATE_TEMPLATE_LABEL_FAIL: 'CREATE_TEMPLATE_LABEL_FAIL',

  DELETE_TEMPLATE_LABEL_REQUEST: 'DELETE_TEMPLATE_LABEL_REQUEST',
  DELETE_TEMPLATE_LABEL_SUCCESS: 'DELETE_TEMPLATE_LABEL_SUCCESS',
  DELETE_TEMPLATE_LABEL_FAIL: 'DELETE_TEMPLATE_LABEL_FAIL',

  ADD_FILE_TEMPLATE_REQUEST: 'ADD_FILE_TEMPLATE_REQUEST',
  ADD_FILE_TEMPLATE_SUCCESS: 'ADD_FILE_TEMPLATE_SUCCESS',
  ADD_FILE_TEMPLATE_FAIL: 'ADD_FILE_TEMPLATE_FAIL',

  GET_FILE_TEMPLATE_REQUEST: 'GET_FILE_TEMPLATE_REQUEST',
  GET_FILE_TEMPLATE_SUCCESS: 'GET_FILE_TEMPLATE_SUCCESS',
  GET_FILE_TEMPLATE_ERROR: 'GET_FILE_TEMPLATE_ERROR',

  EXPORT_CUSTOM_LABELS_REQUEST: 'EXPORT_CUSTOM_LABELS_REQUEST',
  EXPORT_CUSTOM_LABELS_SUCCESS: 'EXPORT_CUSTOM_LABELS_SUCCESS',
  EXPORT_CUSTOM_LABELS_FAIL: 'EXPORT_CUSTOM_LABELS_FAIL',
};
