import { combineReducers } from 'redux';
import { CommonTypes } from '../../action_types';

function previous_route(state = null, action) {
  switch (action.type) {
    case CommonTypes.SET_PREVIOUS_ROUTE:
      return action.data;
    default:
      return state;
  }
}

function statusCode(state = 0, action) {
  switch (action.type) {
    case CommonTypes.SET_STATUS_CODE:
      return action.data;
    default:
      return state;
  }
}

export default combineReducers({
  previous_route,
  statusCode,
});
