import { combineReducers } from 'redux';
import { TemplateTypes } from '../../action_types';

import { handleRequest, initialRequestState } from './helpers';

function getTemplates(state = initialRequestState(), action) {
  return handleRequest(
    TemplateTypes.GET_TEMPLATES_REQUEST,
    TemplateTypes.GET_TEMPLATES_SUCCESS,
    TemplateTypes.GET_TEMPLATES_FAIL,
    state,
    action
  );
}

function getTemplate(state = initialRequestState(), action) {
  return handleRequest(
    TemplateTypes.GET_TEMPLATE_REQUEST,
    TemplateTypes.GET_TEMPLATE_SUCCESS,
    TemplateTypes.GET_TEMPLATE_FAIL,
    state,
    action
  );
}

function createTemplate(state = initialRequestState(), action) {
  return handleRequest(
    TemplateTypes.CREATE_TEMPLATE_REQUEST,
    TemplateTypes.CREATE_TEMPLATE_SUCCESS,
    TemplateTypes.CREATE_TEMPLATE_FAIL,
    state,
    action
  );
}

function updateTemplate(state = initialRequestState(), action) {
  return handleRequest(
    TemplateTypes.UPDATE_TEMPLATE_REQUEST,
    TemplateTypes.UPDATE_TEMPLATE_SUCCESS,
    TemplateTypes.UPDATE_TEMPLATE_FAIL,
    state,
    action
  );
}

function deleteTemplate(state = initialRequestState(), action) {
  return handleRequest(
    TemplateTypes.DELETE_TEMPLATE_REQUEST,
    TemplateTypes.DELETE_TEMPLATE_SUCCESS,
    TemplateTypes.DELETE_TEMPLATE_FAIL,
    state,
    action
  );
}

function getTemplatesDefault(state = initialRequestState(), action) {
  return handleRequest(
    TemplateTypes.GET_TEMPLATES_DEFAULT_REQUEST,
    TemplateTypes.GET_TEMPLATES_DEFAULT_SUCCESS,
    TemplateTypes.GET_TEMPLATES_DEFAULT_FAIL,
    state,
    action
  );
}

function getTemplateLabel(state = initialRequestState(), action) {
  return handleRequest(
    TemplateTypes.GET_TEMPLATE_LABEL_REQUEST,
    TemplateTypes.GET_TEMPLATE_LABEL_SUCCESS,
    TemplateTypes.GET_TEMPLATE_LABEL_FAIL,
    state,
    action
  );
}

function createTemplateLabel(state = initialRequestState(), action) {
  return handleRequest(
    TemplateTypes.CREATE_TEMPLATE_LABEL_REQUEST,
    TemplateTypes.CREATE_TEMPLATE_LABEL_SUCCESS,
    TemplateTypes.CREATE_TEMPLATE_LABEL_FAIL,
    state,
    action
  );
}

function updateTemplateLabel(state = initialRequestState(), action) {
  return handleRequest(
    TemplateTypes.UPDATE_TEMPLATE_LABEL_REQUEST,
    TemplateTypes.UPDATE_TEMPLATE_LABEL_SUCCESS,
    TemplateTypes.UPDATE_TEMPLATE_LABEL_FAIL,
    state,
    action
  );
}

function deleteTemplateLabel(state = initialRequestState(), action) {
  return handleRequest(
    TemplateTypes.DELETE_TEMPLATE_LABEL_REQUEST,
    TemplateTypes.DELETE_TEMPLATE_LABEL_SUCCESS,
    TemplateTypes.DELETE_TEMPLATE_LABEL_FAIL,
    state,
    action
  );
}

function exportCustomLabels(state = initialRequestState(), action) {
  return handleRequest(
    TemplateTypes.EXPORT_CUSTOM_LABELS_REQUEST,
    TemplateTypes.EXPORT_CUSTOM_LABELS_SUCCESS,
    TemplateTypes.EXPORT_CUSTOM_LABELS_FAIL,
    state,
    action
  );
}

export default combineReducers({
  getTemplates,
  getTemplate,
  createTemplate,
  updateTemplate,
  deleteTemplate,
  getTemplateLabel,
  createTemplateLabel,
  updateTemplateLabel,
  deleteTemplateLabel,
  getTemplatesDefault,
  exportCustomLabels,
});
