import { combineReducers } from 'redux';
import { SubmissionTypeTypes } from '../../action_types';

import { handleRequest, initialRequestState } from './helpers';

function getSubmissionTypes(state = initialRequestState(), action) {
  return handleRequest(
    SubmissionTypeTypes.GET_SUBMISSION_TYPES_REQUEST,
    SubmissionTypeTypes.GET_SUBMISSION_TYPES_SUCCESS,
    SubmissionTypeTypes.GET_SUBMISSION_TYPES_FAIL,
    state,
    action
  );
}

function getSubmissionType(state = initialRequestState(), action) {
  return handleRequest(
    SubmissionTypeTypes.GET_SUBMISSION_TYPE_REQUEST,
    SubmissionTypeTypes.GET_SUBMISSION_TYPE_SUCCESS,
    SubmissionTypeTypes.GET_SUBMISSION_TYPE_FAIL,
    state,
    action
  );
}

function updateSubmissionType(state = initialRequestState(), action) {
  return handleRequest(
    SubmissionTypeTypes.UPDATE_SUBMISSION_TYPE_REQUEST,
    SubmissionTypeTypes.UPDATE_SUBMISSION_TYPE_SUCCESS,
    SubmissionTypeTypes.UPDATE_SUBMISSION_TYPE_FAIL,
    state,
    action
  );
}

function getOrgSubmissionTypes(state = initialRequestState(), action) {
  return handleRequest(
    SubmissionTypeTypes.GET_ORG_SUBMISSION_TYPES_REQUEST,
    SubmissionTypeTypes.GET_ORG_SUBMISSION_TYPES_SUCCESS,
    SubmissionTypeTypes.GET_ORG_SUBMISSION_TYPES_FAIL,
    state,
    action
  );
}

function updateOrgSubmissionType(state = initialRequestState(), action) {
  return handleRequest(
    SubmissionTypeTypes.UPDATE_ORG_SUBMISSION_TYPE_REQUEST,
    SubmissionTypeTypes.UPDATE_ORG_SUBMISSION_TYPE_SUCCESS,
    SubmissionTypeTypes.UPDATE_ORG_SUBMISSION_TYPE_FAIL,
    state,
    action
  );
}

function superAdminGetOrgSubmissionTypes(state = initialRequestState(), action) {
  return handleRequest(
    SubmissionTypeTypes.SUPER_ADMIN_GET_ORG_SUBMISSION_TYPES_REQUEST,
    SubmissionTypeTypes.SUPER_ADMIN_GET_ORG_SUBMISSION_TYPES_SUCCESS,
    SubmissionTypeTypes.SUPER_ADMIN_GET_ORG_SUBMISSION_TYPES_FAIL,
    state,
    action
  );
}

function superAdminUpdateOrgSubmissionType(state = initialRequestState(), action) {
  return handleRequest(
    SubmissionTypeTypes.SUPER_ADMIN_UPDATE_ORG_SUBMISSION_TYPE_REQUEST,
    SubmissionTypeTypes.SUPER_ADMIN_UPDATE_ORG_SUBMISSION_TYPE_SUCCESS,
    SubmissionTypeTypes.SUPER_ADMIN_UPDATE_ORG_SUBMISSION_TYPE_FAIL,
    state,
    action
  );
}

export default combineReducers({
  getSubmissionTypes,
  getSubmissionType,
  updateSubmissionType,
  getOrgSubmissionTypes,
  updateOrgSubmissionType,
  superAdminGetOrgSubmissionTypes,
  superAdminUpdateOrgSubmissionType,
});
