import React, { lazy, Suspense } from 'react';
import { Route, Redirect, BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';
import Loader from 'src/components/utility/loader';
import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE } from './route.constants';
import Auth from 'src/components/Auth';
import { QueryParamProvider } from 'use-query-params';

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));
const Page404 = lazy(() => import('./containers/Pages/404'));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('@app/containers/Pages/Login')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    exact: true,
    component: lazy(() => import('@app/containers/Pages/Login')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    exact: true,
    component: lazy(() => import('@app/containers/Pages/Signup')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP_SUCCESS,
    exact: true,
    component: lazy(() => import('@app/containers/Pages/Signup/SignupSuccess')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP_COMPLETE,
    exact: true,
    component: lazy(() => import('@app/containers/Pages/CreatePassword')),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    exact: true,
    component: lazy(() => import('@app/containers/Pages/ForgetPassword')),
  },
  {
    path: PUBLIC_ROUTE.CREATE_ORG,
    exact: true,
    component: lazy(() => import('@app/containers/Pages/AddOrg')),
  },
  {
    path: PUBLIC_ROUTE.CREATE_ORG_SUCCESS,
    exact: true,
    component: lazy(() => import('@app/containers/Pages/AddOrg/AddOrgSuccess')),
  },
  {
    path: PUBLIC_ROUTE.JOIN_ORG,
    component: lazy(() => import('@app/containers/Pages/Loading')),
  },
  {
    path: PUBLIC_ROUTE.CREATE_ACCOUNT,
    component: lazy(() => import('@app/containers/Pages/CreateAccount')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_403,
    component: lazy(() => import('@app/containers/Pages/403')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import('@app/containers/Pages/404')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import('@app/containers/Pages/500')),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() => import('./containers/Pages/ResetPassword')),
  },
];

function PrivateRoute({ children, ...rest }) {
  let location = useLocation();
  const isLoggedIn = Auth.isLoggedIn();
  if (isLoggedIn) return <Route {...rest}>{children}</Route>;
  return (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: location },
      }}
    />
  );
}
export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router
          getUserConfirmation={() => {
            /* Empty callback to block the default browser prompt */
          }}
        >
          <QueryParamProvider ReactRouterRoute={Route}>
            <Switch>
              {publicRoutes.map((route, index) => (
                <Route key={index} path={route.path} exact={route.exact}>
                  <route.component />
                </Route>
              ))}
              <PrivateRoute path='/'>
                <Dashboard />
              </PrivateRoute>
              <Route component={Page404} />
            </Switch>
          </QueryParamProvider>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
