import { combineReducers } from 'redux';
import { AuthTypes } from './docbase/action_types';
import App from 'src/redux/app/reducer';
import ThemeSwitcher from 'src/redux/themeSwitcher/reducer';
import LanguageSwitcher from 'src/redux/languageSwitcher/reducer';

import docbase from './docbase/reducers';

const appReducer = combineReducers({
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  docbase,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === AuthTypes.LOGOUT_SUCCESS) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
