import { combineReducers } from 'redux';
import { BVDocumentTypes, SubmissionTypes } from '../../action_types';
import { arrayToObject, removeItem, insertItem, updateObjectInArray } from 'src/utils/helpers';
import { toObject } from 'src/utils/helpers';

function all(state = {}, action) {
  switch (action.type) {
    case BVDocumentTypes.GET_DOCUMENTS_SUCCESS:
      return { ...state, ...arrayToObject(action.data.results) };
    case BVDocumentTypes.GET_BV_DOCUMENT_SUCCESS:
    case BVDocumentTypes.VALIDATE_DOCUMENT_SUCCESS:
    case BVDocumentTypes.VERIFY_DOCUMENT_SUCCESS:
    case BVDocumentTypes.REJECT_DOCUMENT_SUCCESS:
    case BVDocumentTypes.CREATE_DOCUMENT_SUCCESS:
    case BVDocumentTypes.UPDATE_DOCUMENT_SUCCESS:
    case BVDocumentTypes.RESET_DOCUMENT_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      };
    case BVDocumentTypes.ARCHIVE_DOCUMENT_SUCCESS:
    case BVDocumentTypes.CLEAR_DOCUMENT:
      const { [action.id]: _, ...newState } = state;
      return newState;
    default:
      return state;
  }
}

function ui(state = [], action) {
  switch (action.type) {
    case BVDocumentTypes.GET_DOCUMENTS_SUCCESS:
      return action.data.results;
    case BVDocumentTypes.CREATE_DOCUMENT_SUCCESS:
      return insertItem(state, action.data);
    case BVDocumentTypes.UPDATE_DOCUMENT_SUCCESS:
    case BVDocumentTypes.VALIDATE_DOCUMENT_SUCCESS:
    case BVDocumentTypes.VERIFY_DOCUMENT_SUCCESS:
    case BVDocumentTypes.REJECT_DOCUMENT_SUCCESS:
      return updateObjectInArray(state, action.data);
    case BVDocumentTypes.ARCHIVE_DOCUMENT_SUCCESS:
      return removeItem(state, action.id);
    case BVDocumentTypes.CLEAR_DOCUMENTS:
      return [];
    default:
      return state;
  }
}

function meta(state = {}, action) {
  switch (action.type) {
    case BVDocumentTypes.GET_DOCUMENTS_SUCCESS:
      const { results, ...rest } = action.data;
      return { ...state, ...rest };
    case BVDocumentTypes.ARCHIVE_DOCUMENT_SUCCESS: {
      const { count } = state;
      return { ...state, count: count - 1 };
    }
    default:
      return state;
  }
}

function images(state = {}, action) {
  switch (action.type) {
    case BVDocumentTypes.UPLOAD_DOCUMENT_IMAGE_SUCCESS:
      return { ...state, [action.id]: action.image };
    case BVDocumentTypes.GET_DOCUMENT_IMAGE_SUCCESS:
    case BVDocumentTypes.GET_PAGE_IMAGE_SUCCESS:
      return { ...state, [action.id]: action.data };
    default:
      return state;
  }
}

function images_original(state = {}, action) {
  switch (action.type) {
    case BVDocumentTypes.GET_DOCUMENT_IMAGE_ORIGIN_SUCCESS:
    case BVDocumentTypes.GET_PAGE_IMAGE_ORIGIN_SUCCESS:
      return { ...state, [action.id]: action.data };
    default:
      return state;
  }
}

function data(state = {}, action) {
  switch (action.type) {
    case BVDocumentTypes.GET_DOCUMENT_DATA_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      };
    case BVDocumentTypes.CLEAR_DOCUMENT_DATA:
      const { [action.id]: _, ...newState } = state;
      return newState;
    default:
      return state;
  }
}

function data_json(state = {}, action) {
  switch (action.type) {
    case BVDocumentTypes.GET_DOCUMENT_DATA_JSON_SUCCESS:
      return {
        ...state,
        [action.file_id]: action.data,
      };
    default:
      return state;
  }
}

function data_lookup(state = {}, action) {
  switch (action.type) {
    case BVDocumentTypes.GET_DOCUMENT_DATA_SUCCESS: {
      let obj = {};
      action.data.page_set.forEach(page => {
        if (state[page.id] !== 1) {
          obj[page.id] = page.lookup_result;
        }
      });
      return {
        ...state,
        ...obj,
      };
    }
    case BVDocumentTypes.LOOKUP_DOCUMENT_REQUEST: {
      return {
        ...state,
        [action.id]: 1,
      };
    }
    case BVDocumentTypes.LOOKUP_DOCUMENT_SUCCESS: {
      return {
        ...state,
        [action.id]: action.data,
      };
    }
    case BVDocumentTypes.LOOKUP_DOCUMENT_FAIL: {
      return {
        ...state,
        [action.id]: 0,
      };
    }
    default:
      return state;
  }
}

function screenshot(state = {}, action) {
  switch (action.type) {
    case BVDocumentTypes.GET_SCREENSHOT_SUCCESS:
      return { ...state, [action.id]: action.data };
    default:
      return state;
  }
}

function preview(state = {}, action) {
  switch (action.type) {
    case BVDocumentTypes.PREVIEW_DOCUMENT_SUCCESS:
      return { ...state, [action.id]: action.data };
    default:
      return state;
  }
}

function preview_image(state = {}, action) {
  switch (action.type) {
    case BVDocumentTypes.PREVIEW_IMAGE_SUCCESS:
      return { ...state, [action.id]: action.data };
    default:
      return state;
  }
}

function focusedCellId(state = '', action) {
  switch (action.type) {
    case BVDocumentTypes.SET_FOCUSED_CELL_ID:
      return action.data;
    default:
      return state;
  }
}

export default combineReducers({
  all,
  ui,
  meta,
  images,
  images_original,
  data,
  data_json,
  screenshot,
  data_lookup,
  preview,
  preview_image,
  focusedCellId,
});
