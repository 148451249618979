export default {
  GET_SUBMISSIONS_SUMMARY_REQUEST: 'GET_SUBMISSIONS_SUMMARY_REQUEST',
  GET_SUBMISSIONS_SUMMARY_SUCCESS: 'GET_SUBMISSIONS_SUMMARY_SUCCESS',
  GET_SUBMISSIONS_SUMMARY_FAIL: 'GET_SUBMISSIONS_SUMMARY_FAIL',

  GET_STATISTICS_BY_DAY_REQUEST: 'GET_STATISTICS_BY_DAY_REQUEST',
  GET_STATISTICS_BY_DAY_SUCCESS: 'GET_STATISTICS_BY_DAY_SUCCESS',
  GET_STATISTICS_BY_DAY_FAIL: 'GET_STATISTICS_BY_DAY_FAIL',

  GET_STATISTICS_BY_WEEK_REQUEST: 'GET_STATISTICS_BY_WEEK_REQUEST',
  GET_STATISTICS_BY_WEEK_SUCCESS: 'GET_STATISTICS_BY_WEEK_SUCCESS',
  GET_STATISTICS_BY_WEEK_FAIL: 'GET_STATISTICS_BY_WEEK_FAIL',

  GET_STATISTICS_BY_MONTH_REQUEST: 'GET_STATISTICS_BY_MONTH_REQUEST',
  GET_STATISTICS_BY_MONTH_SUCCESS: 'GET_STATISTICS_BY_MONTH_SUCCESS',
  GET_STATISTICS_BY_MONTH_FAIL: 'GET_STATISTICS_BY_MONTH_FAIL',
};
