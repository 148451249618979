import { combineReducers } from 'redux';
import { InputEmailTypes } from '../../action_types';

import { handleRequest, initialRequestState } from './helpers';

function getInputEmails(state = initialRequestState(), action) {
  return handleRequest(
    InputEmailTypes.GET_INPUT_EMAILS_REQUEST,
    InputEmailTypes.GET_INPUT_EMAILS_SUCCESS,
    InputEmailTypes.GET_INPUT_EMAILS_FAIL,
    state,
    action
  );
}

function getInputEmail(state = initialRequestState(), action) {
  return handleRequest(
    InputEmailTypes.GET_INPUT_EMAIL_REQUEST,
    InputEmailTypes.GET_INPUT_EMAIL_SUCCESS,
    InputEmailTypes.GET_INPUT_EMAIL_FAIL,
    state,
    action
  );
}

function getMailDomain(state = initialRequestState(), action) {
  return handleRequest(
    InputEmailTypes.GET_MAIL_DOMAIN_REQUEST,
    InputEmailTypes.GET_MAIL_DOMAIN_SUCCESS,
    InputEmailTypes.GET_MAIL_DOMAIN_FAIL,
    state,
    action
  );
}

export default combineReducers({
  getInputEmails,
  getInputEmail,
  getMailDomain,
});
