export default {
  GET_USERS_REQUEST: 'GET_USERS_REQUEST',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_FAIL: 'GET_USERS_FAIL',

  SEARCH_USERS_REQUEST: 'SEARCH_USERS_REQUEST',
  SEARCH_USERS_SUCCESS: 'SEARCH_USERS_SUCCESS',
  SEARCH_USERS_FAIL: 'SEARCH_USERS_FAIL',

  GET_USER_REQUEST: 'GET_USER_REQUEST',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAIL: 'GET_USER_FAIL',

  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAIL: 'UPDATE_USER_FAIL',

  CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_FAIL: 'CREATE_USER_FAIL',

  DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FAIL: 'DELETE_USER_FAIL',

  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAIL: 'CHANGE_PASSWORD_FAIL',

  GET_USER_PROFILE_IMAGE_REQUEST: 'GET_USER_PROFILE_IMAGE_REQUEST',
  GET_USER_PROFILE_IMAGE_SUCCESS: 'GET_USER_PROFILE_IMAGE_SUCCESS',
  GET_USER_PROFILE_IMAGE_FAIL: 'GET_USER_PROFILE_IMAGE_FAIL',

  GET_MY_PROFILE_IMAGE_REQUEST: 'GET_MY_PROFILE_IMAGE_REQUEST',
  GET_MY_PROFILE_IMAGE_SUCCESS: 'GET_MY_PROFILE_IMAGE_SUCCESS',
  GET_MY_PROFILE_IMAGE_FAIL: 'GET_MY_PROFILE_IMAGE_FAIL',

  UPLOAD_MY_PROFILE_IMAGE_REQUEST: 'UPLOAD_MY_PROFILE_IMAGE_REQUEST',
  UPLOAD_MY_PROFILE_IMAGE_SUCCESS: 'UPLOAD_MY_PROFILE_IMAGE_SUCCESS',
  UPLOAD_MY_PROFILE_IMAGE_FAIL: 'UPLOAD_MY_PROFILE_IMAGE_FAIL',
};
