import { combineReducers } from 'redux';
import { StatisticsTypes } from '../../action_types';

import { handleRequest, initialRequestState } from './helpers';

function getSubmissionsSummary(state = initialRequestState(), action) {
  return handleRequest(
    StatisticsTypes.GET_SUBMISSIONS_SUMMARY_REQUEST,
    StatisticsTypes.GET_SUBMISSIONS_SUMMARY_SUCCESS,
    StatisticsTypes.GET_SUBMISSIONS_SUMMARY_FAIL,
    state,
    action
  );
}

function getStatisticsByDay(state = initialRequestState(), action) {
  return handleRequest(
    StatisticsTypes.GET_STATISTICS_BY_DAY_REQUEST,
    StatisticsTypes.GET_STATISTICS_BY_DAY_SUCCESS,
    StatisticsTypes.GET_STATISTICS_BY_DAY_FAIL,
    state,
    action
  );
}

function getStatisticsByWeek(state = initialRequestState(), action) {
  return handleRequest(
    StatisticsTypes.GET_STATISTICS_BY_WEEK_REQUEST,
    StatisticsTypes.GET_STATISTICS_BY_WEEK_SUCCESS,
    StatisticsTypes.GET_STATISTICS_BY_WEEK_FAIL,
    state,
    action
  );
}

function getStatisticsByMonth(state = initialRequestState(), action) {
  return handleRequest(
    StatisticsTypes.GET_STATISTICS_BY_MONTH_REQUEST,
    StatisticsTypes.GET_STATISTICS_BY_MONTH_SUCCESS,
    StatisticsTypes.GET_STATISTICS_BY_MONTH_FAIL,
    state,
    action
  );
}
export default combineReducers({
  getSubmissionsSummary,
  getStatisticsByDay,
  getStatisticsByWeek,
  getStatisticsByMonth,
});
