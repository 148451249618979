import { combineReducers } from 'redux';
import { FieldDataTypes, FileTypes, SubmissionTypes, BVDocumentTypes, DatapointTypes } from '../../action_types';
import { arrayToObject } from 'src/utils/helpers';
import { removeItem, updateObjectInArray } from 'src/utils/helpers';

function all(state = {}, action) {
  switch (action.type) {
    case FieldDataTypes.SET_FIELD_DATAS:
      return { ...state, ...arrayToObject(action.data) };
    case FieldDataTypes.GET_FIELD_DATAS_SUCCESS:
      return { ...state, ...arrayToObject(action.data.results) };
    case FieldDataTypes.GET_SUBMISSION_FIELD_SUCCESS: {
      let newState = { ...state };
      action.data.submission_data_set.map(dt => {
        if (dt.category === 'section') {
          newState = {
            ...newState,
            ...arrayToObject(dt.field_data_set),
          };
        } else {
          newState = {
            ...newState,
            [dt.id]: dt,
          };
        }
      });
      return newState;
    }
    // case FieldDataTypes.UPDATE_FIELD_DATA_SUCCESS:
    case FieldDataTypes.MAP_DATAPOINT_FIELD_DATA_SUCCESS:
    case FieldDataTypes.VALIDATE_FIELD_DATA_SUCCESS:
    case FieldDataTypes.VERIFY_FIELD_DATA_SUCCESS:
    case FieldDataTypes.REJECT_FIELD_DATA_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      };
    // case FieldDataTypes.VALIDATE_FIELD_DATA_SUCCESS:
    // case FieldDataTypes.VERIFY_FIELD_DATA_SUCCESS:
    // case FieldDataTypes.REJECT_FIELD_DATA_SUCCESS:
    case FieldDataTypes.UPDATE_FIELD_DATA_SUCCESS:
      return {
        ...state,
        [action.data.id]: { ...state[action.data.id], status: action.data.status },
      };
    case FieldDataTypes.GET_FIELD_DATA_SUCCESS: {
      if (action.field_data_type === 'table') {
        const dataTable = action.data.field_data_set.flatMap(row => row.field_data_set);
        return {
          ...state,
          [action.data.id]: action.data,
          ...arrayToObject(dataTable),
        };
      }
      return {
        ...state,
        [action.data.id]: action.data,
      };
    }
    case FieldDataTypes.ADD_FIELD_ROW_SUCCESS: {
      const { parent } = action;
      return {
        ...state,
        [parent]: { ...state[parent], field_data_set: [...state[parent].field_data_set, action.data] },
      };
    }
    case FieldDataTypes.ADD_FIELD_COLUMN_SUCCESS: {
      const { parent } = action;
      const data_table = [...state[parent].field_data_set];
      return {
        ...state,
        [parent]: {
          ...state[parent],
          field_data_set: data_table.map((row, index) => ({
            ...row,
            field_data_set: [...row.field_data_set, action.data[index]],
          })),
        },
      };
    }
    case FieldDataTypes.DELETE_FIELD_ROW_SUCCESS: {
      const { id, parent } = action.data;
      return { ...state, [parent]: { ...state[parent], field_data_set: removeItem(state[parent].field_data_set, id) } };
    }
    case FieldDataTypes.DELETE_FIELD_COLUMN_SUCCESS: {
      const { parent, position } = action;
      const data_table = [...state[parent].field_data_set];
      return {
        ...state,
        [parent]: {
          ...state[parent],
          field_data_set: data_table.map(row => ({
            ...row,
            field_data_set: row.field_data_set.filter((col, index) => index !== position),
          })),
        },
      };
    }
    case FieldDataTypes.UPDATE_FIELD_DATA_NAME_SUCCESS: {
      const { id, value } = action.data;
      return {
        ...state,
        [id]: { ...state[id], value: value },
      };
    }
    default:
      return state;
  }
}

function fields(state = {}, action) {
  switch (action.type) {
    case FieldDataTypes.GET_SUBMISSION_FIELD_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data.submission_data_set,
      };
    // case FieldDataTypes.UPDATE_FIELD_DATA_NAME_SUCCESS:
    //   return {
    //     ...state,
    //     [action.submissionId]: updateObjectInArray(state[action.submissionId], {
    //       id: action.data.id,
    //       value: action.data.value,
    //     }),
    //   };
    default:
      return state;
  }
}

function updatedFieldData(state = {}, action) {
  switch (action.type) {
    case FieldDataTypes.VALIDATE_FIELD_DATA_SUCCESS:
    case FieldDataTypes.VERIFY_FIELD_DATA_SUCCESS:
    case FieldDataTypes.REJECT_FIELD_DATA_SUCCESS:
      return { ...state, ...action.data };
    default:
      return state;
  }
}

function rejectedIds(state = [], action) {
  switch (action.type) {
    case FieldDataTypes.VERIFY_FIELD_DATA_SUCCESS:
      return [...state].filter(id => id !== action.data.id);
    case FieldDataTypes.REJECT_FIELD_DATA_SUCCESS:
      return [...state, action.data.id];
    // case FileTypes.REJECT_FILE_SUCCESS:
    case FileTypes.GET_FILE_DATA_SUCCESS:
      return [];
    case BVDocumentTypes.GET_BV_DOCUMENT_SUCCESS:
      return [];
    case FieldDataTypes.ADD_REJECTED_FIELD_DATA:
      const isExisted = state.includes(action.data);
      return isExisted ? state : [...state, action.data];
    default:
      return state;
  }
}

function values(state = {}, action) {
  switch (action.type) {
    case FieldDataTypes.CHANGE_FIELD_DATA_VALUE:
      return action.id
        ? {
            ...state,
            [action.id]: action.data,
          }
        : state;
    case FieldDataTypes.RESET_VALUES:
    case BVDocumentTypes.RESET_DOCUMENT_SUCCESS:
      return {};
    default:
      return state;
  }
}

function activeFieldData(state = {}, action) {
  switch (action.type) {
    case FieldDataTypes.SET_ACTIVE_FIELD_DATA:
      return action.data;
    case DatapointTypes.UPDATE_DATAPOINT_SUCCESS: {
      if (action.data.id === state.id) {
        return action.data;
      }
      return state;
    }

    default:
      return state;
  }
}

export default combineReducers({
  all,
  fields,
  rejectedIds,
  updatedFieldData,
  values,
  activeFieldData,
});
