import { combineReducers } from 'redux';
import { ApiKeyTypes } from '../../action_types';
import { arrayToObject, removeItem, insertItem, updateObjectInArray } from 'src/utils/helpers';

function all(state = {}, action) {
  switch (action.type) {
    case ApiKeyTypes.GET_API_KEYS_SUCCESS:
      return { ...state, ...arrayToObject(action.data.results) };
    case ApiKeyTypes.GET_API_KEY_SUCCESS:
    case ApiKeyTypes.CREATE_API_KEY_SUCCESS:
    case ApiKeyTypes.UPDATE_API_KEY_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      };
    case ApiKeyTypes.DELETE_API_KEY_SUCCESS:
      const { [action.id]: _, ...newState } = state;
      return newState;
    default:
      return state;
  }
}

function ui(state = [], action) {
  switch (action.type) {
    case ApiKeyTypes.GET_API_KEYS_SUCCESS:
      return action.data.results;
    case ApiKeyTypes.CREATE_API_KEY_SUCCESS:
      return insertItem(state, action.data);
    case ApiKeyTypes.UPDATE_API_KEY_SUCCESS:
      return updateObjectInArray(state, action.data);
    case ApiKeyTypes.DELETE_API_KEY_SUCCESS:
      return removeItem(state, action.id);
    default:
      return state;
  }
}

function meta(state = {}, action) {
  switch (action.type) {
    case ApiKeyTypes.GET_API_KEYS_SUCCESS:
      const { results, ...rest } = action.data;
      return { ...state, ...rest };
    case ApiKeyTypes.DELETE_API_KEY_SUCCESS: {
      const { count } = state;
      return { ...state, count: count - 1 };
    }
    default:
      return state;
  }
}

function createdApiKey(state = {}, action) {
  switch (action.type) {
    case ApiKeyTypes.CREATE_API_KEY_SUCCESS:
      return action.data;
    default:
      return state;
  }
}

export default combineReducers({
  all,
  ui,
  meta,
  createdApiKey,
});
