export default {
  GET_SUBMISSION_REQUEST: 'GET_SUBMISSION_REQUEST',
  GET_SUBMISSION_SUCCESS: 'GET_SUBMISSION_SUCCESS',
  GET_SUBMISSION_FAIL: 'GET_SUBMISSION_FAIL',

  GET_SUBMISSIONS_REQUEST: 'GET_SUBMISSIONS_REQUEST',
  GET_SUBMISSIONS_SUCCESS: 'GET_SUBMISSIONS_SUCCESS',
  GET_SUBMISSIONS_FAIL: 'GET_SUBMISSIONS_FAIL',

  UPDATE_SUBMISSION_REQUEST: 'UPDATE_SUBMISSION_REQUEST',
  UPDATE_SUBMISSION_SUCCESS: 'UPDATE_SUBMISSION_SUCCESS',
  UPDATE_SUBMISSION_FAIL: 'UPDATE_SUBMISSION_FAIL',

  UPLOAD_SUBMISSION_REQUEST: 'UPLOAD_SUBMISSION_REQUEST',
  UPLOAD_SUBMISSION_SUCCESS: 'UPLOAD_SUBMISSION_SUCCESS',
  UPLOAD_SUBMISSION_FAIL: 'UPLOAD_SUBMISSION_FAIL',

  DELETE_SUBMISSIONS_REQUEST: 'DELETE_SUBMISSIONS_REQUEST',
  DELETE_SUBMISSIONS_SUCCESS: 'DELETE_SUBMISSIONS_SUCCESS',
  DELETE_SUBMISSIONS_FAIL: 'DELETE_SUBMISSIONS_FAIL',

  VALIDATE_SUBMISSION_REQUEST: 'VALIDATE_SUBMISSION_REQUEST',
  VALIDATE_SUBMISSION_SUCCESS: 'VALIDATE_SUBMISSION_SUCCESS',
  VALIDATE_SUBMISSION_FAIL: 'VALIDATE_SUBMISSION_FAIL',

  VERIFY_SUBMISSION_REQUEST: 'VERIFY_SUBMISSION_REQUEST',
  VERIFY_SUBMISSION_SUCCESS: 'VERIFY_SUBMISSION_SUCCESS',
  VERIFY_SUBMISSION_FAIL: 'VERIFY_SUBMISSION_FAIL',

  REJECT_SUBMISSION_REQUEST: 'REJECT_SUBMISSION_REQUEST',
  REJECT_SUBMISSION_SUCCESS: 'REJECT_SUBMISSION_SUCCESS',
  REJECT_SUBMISSION_FAIL: 'REJECT_SUBMISSION_FAIL',

  SET_CURRENT_DOCUMENTS: 'SET_CURRENT_DOCUMENTS',

  SET_MAP_DOCUMENT_TYPE: 'SET_MAP_DOCUMENT_TYPE',

  SET_ORIGIN_COLUMNS: 'SET_ORIGIN_COLUMNS',

  SET_ORIGIN_ORDERED: 'SET_ORIGIN_ORDERED',

  SET_SORTED_COLUMNS: 'SET_SORTED_COLUMNS',

  SET_ORDERED: 'SET_ORDERED',

  SET_TEMP_COLUMNS: 'SET_TEMP_COLUMNS',

  SET_TEMP_ORDERED: 'SET_TEMP_ORDERED',

  SET_OLD_ID: 'SET_OLD_ID',

  SET_NEW_ID: 'SET_NEW_ID',

  SET_CURRENT_PAGES: 'SET_CURRENT_PAGES',

  ASSIGN_SUBMISSION_TO_VALIDATOR_REQUEST: 'ASSIGN_SUBMISSION_TO_VALIDATOR_REQUEST',
  ASSIGN_SUBMISSION_TO_VALIDATOR_SUCCESS: 'ASSIGN_SUBMISSION_TO_VALIDATOR_SUCCESS',
  ASSIGN_SUBMISSION_TO_VALIDATOR_FAIL: 'ASSIGN_SUBMISSION_TO_VALIDATOR_FAIL',

  MOVE_SUBMISSIONS_REQUEST: 'MOVE_SUBMISSIONS_REQUEST',
  MOVE_SUBMISSIONS_SUCCESS: 'MOVE_SUBMISSIONS_SUCCESS',
  MOVE_SUBMISSIONS_FAIL: 'MOVE_SUBMISSIONS_FAIL',

  GET_SUBMISSIONS_NUMBER_REQUEST: 'GET_SUBMISSIONS_NUMBER_REQUEST',
  GET_SUBMISSIONS_NUMBER_SUCCESS: 'GET_SUBMISSIONS_NUMBER_SUCCESS',
  GET_SUBMISSIONS_NUMBER_FAIL: 'GET_SUBMISSIONS_NUMBER_FAIL',

  SET_SUBMISSIONS_TO_ASSIGN: 'SET_SUBMISSIONS_TO_ASSIGN',

  SET_ASSIGN_RESULT: 'SET_ASSIGN_RESULT',

  RETRY_OCR_SUBMISSIONS_REQUEST: 'RETRY_OCR_SUBMISSIONS_REQUEST',
  RETRY_OCR_SUBMISSIONS_SUCCESS: 'RETRY_OCR_SUBMISSIONS_SUCCESS',
  RETRY_OCR_SUBMISSIONS_FAIL: 'RETRY_OCR_SUBMISSIONS_FAIL',

  GET_SUBMISSION_THUMB_REQUEST: 'GET_SUBMISSION_THUMB_REQUEST',
  GET_SUBMISSION_THUMB_SUCCESS: 'GET_SUBMISSION_THUMB_SUCCESS',
  GET_SUBMISSION_THUMB_FAIL: 'GET_SUBMISSION_THUMB_FAIL',

  GET_SUBMISSION_BY_SOCKET_REQUEST: 'GET_SUBMISSION_BY_SOCKET_REQUEST',
  GET_SUBMISSION_BY_SOCKET_SUCCESS: 'GET_SUBMISSION_BY_SOCKET_SUCCESS',
  GET_SUBMISSION_BY_SOCKET_FAIL: 'GET_SUBMISSION_BY_SOCKET_FAIL',

  VALIDATE_SUBMISSIONS_REQUEST: 'VALIDATE_SUBMISSIONS_REQUEST',
  VALIDATE_SUBMISSIONS_SUCCESS: 'VALIDATE_SUBMISSIONS_SUCCESS',
  VALIDATE_SUBMISSIONS_FAIL: 'VALIDATE_SUBMISSIONS_FAIL',

  VERIFY_SUBMISSIONS_REQUEST: 'VERIFY_SUBMISSIONS_REQUEST',
  VERIFY_SUBMISSIONS_SUCCESS: 'VERIFY_SUBMISSIONS_SUCCESS',
  VERIFY_SUBMISSIONS_FAIL: 'VERIFY_SUBMISSIONS_FAIL',

  REMOVE_SUBMISSION_FROM_LIST: 'REMOVE_SUBMISSION_FROM_LIST',

  EXPORT_SUBMISSIONS_REQUEST: 'EXPORT_SUBMISSIONS_REQUEST',
  EXPORT_SUBMISSIONS_SUCCESS: 'EXPORT_SUBMISSIONS_SUCCESS',
  EXPORT_SUBMISSIONS_FAIL: 'EXPORT_SUBMISSIONS_FAIL',

  SET_SHOW_ACTIVE_BOX: 'SET_SHOW_ACTIVE_BOX',
};
