import { combineReducers } from 'redux';
import { InputEmailTypes } from '../../action_types';
import { arrayToObject, removeItem, insertItem, updateObjectInArray } from 'src/utils/helpers';

function all(state = {}, action) {
  switch (action.type) {
    case InputEmailTypes.GET_INPUT_EMAILS_SUCCESS:
      return { ...state, ...arrayToObject(action.data.results) };
    case InputEmailTypes.GET_INPUT_EMAIL_SUCCESS:
    case InputEmailTypes.CREATE_INPUT_EMAIL_SUCCESS:
    case InputEmailTypes.UPDATE_INPUT_EMAIL_SUCCESS:
      return {
        ...state,
        [action.data.id]: action.data,
      };
    case InputEmailTypes.DELETE_INPUT_EMAIL_SUCCESS:
      const { [action.id]: _, ...newState } = state;
      return newState;
    default:
      return state;
  }
}

function ui(state = [], action) {
  switch (action.type) {
    case InputEmailTypes.GET_INPUT_EMAILS_SUCCESS:
      return action.data.results;
    case InputEmailTypes.CREATE_INPUT_EMAIL_SUCCESS:
      return insertItem(state, action.data);
    case InputEmailTypes.UPDATE_INPUT_EMAIL_SUCCESS:
      return updateObjectInArray(state, action.data);
    case InputEmailTypes.DELETE_INPUT_EMAIL_SUCCESS:
      return removeItem(state, action.id);
    default:
      return state;
  }
}

function meta(state = {}, action) {
  switch (action.type) {
    case InputEmailTypes.GET_INPUT_EMAILS_SUCCESS:
      const { results, ...rest } = action.data;
      return { ...state, ...rest };
    case InputEmailTypes.DELETE_INPUT_EMAIL_SUCCESS: {
      const { count } = state;
      return { ...state, count: count - 1 };
    }
    default:
      return state;
  }
}

function mailDomain(state = {}, action) {
  switch (action.type) {
    case InputEmailTypes.GET_MAIL_DOMAIN_SUCCESS:
      return { ...state, ...action.data };
    default:
      return state;
  }
}

function params(state = { page: 1, folder_id: '' }, action) {
  switch (action.type) {
    case InputEmailTypes.SET_PARAMS:
      return { ...state, ...action.data };
    default:
      return state;
  }
}

export default combineReducers({
  all,
  ui,
  meta,
  mailDomain,
  params,
});
