import { combineReducers } from 'redux';
import { BillingPolicyTypes } from '../../action_types';

import { handleRequest, initialRequestState } from './helpers';

function getBillingPolicies(state = initialRequestState(), action) {
  return handleRequest(
    BillingPolicyTypes.GET_BILLING_POLICIES_REQUEST,
    BillingPolicyTypes.GET_BILLING_POLICIES_SUCCESS,
    BillingPolicyTypes.GET_BILLING_POLICIES_FAIL,
    state,
    action
  );
}

function createBillingPolicy(state = initialRequestState(), action) {
  return handleRequest(
    BillingPolicyTypes.CREATE_BILLING_POLICY_REQUEST,
    BillingPolicyTypes.CREATE_BILLING_POLICY_SUCCESS,
    BillingPolicyTypes.CREATE_BILLING_POLICY_FAIL,
    state,
    action
  );
}

function updateBillingPolicy(state = initialRequestState(), action) {
  return handleRequest(
    BillingPolicyTypes.UPDATE_BILLING_POLICY_REQUEST,
    BillingPolicyTypes.UPDATE_BILLING_POLICY_SUCCESS,
    BillingPolicyTypes.UPDATE_BILLING_POLICY_FAIL,
    state,
    action
  );
}

function deleteBillingPolicy(state = initialRequestState(), action) {
  return handleRequest(
    BillingPolicyTypes.DELETE_BILLING_POLICY_REQUEST,
    BillingPolicyTypes.DELETE_BILLING_POLICY_SUCCESS,
    BillingPolicyTypes.DELETE_BILLING_POLICY_FAIL,
    state,
    action
  );
}

function getOrgBilling(state = initialRequestState(), action) {
  return handleRequest(
    BillingPolicyTypes.GET_ORG_BILLING_REQUEST,
    BillingPolicyTypes.GET_ORG_BILLING_SUCCESS,
    BillingPolicyTypes.GET_ORG_BILLING_FAIL,
    state,
    action
  );
}

function updateOrgBilling(state = initialRequestState(), action) {
  return handleRequest(
    BillingPolicyTypes.UPDATE_ORG_BILLING_REQUEST,
    BillingPolicyTypes.UPDATE_ORG_BILLING_SUCCESS,
    BillingPolicyTypes.UPDATE_ORG_BILLING_FAIL,
    state,
    action
  );
}

function createCredit(state = initialRequestState(), action) {
  return handleRequest(
    BillingPolicyTypes.CREATE_CREDIT_REQUEST,
    BillingPolicyTypes.CREATE_CREDIT_SUCCESS,
    BillingPolicyTypes.CREATE_CREDIT_FAIL,
    state,
    action
  );
}

export default combineReducers({
  getBillingPolicies,
  createBillingPolicy,
  updateBillingPolicy,
  deleteBillingPolicy,
  getOrgBilling,
  updateOrgBilling,
  createCredit,
});
