import { combineReducers } from 'redux';
import { WebhookTypes } from '../../action_types';

import { handleRequest, initialRequestState } from './helpers';

function getWebhooks(state = initialRequestState(), action) {
  return handleRequest(
    WebhookTypes.GET_WEBHOOKS_REQUEST,
    WebhookTypes.GET_WEBHOOKS_SUCCESS,
    WebhookTypes.GET_WEBHOOKS_FAIL,
    state,
    action
  );
}

function getWebhookEvents(state = initialRequestState(), action) {
  return handleRequest(
    WebhookTypes.GET_WEBHOOK_EVENTS_REQUEST,
    WebhookTypes.GET_WEBHOOK_EVENTS_SUCCESS,
    WebhookTypes.GET_WEBHOOK_EVENTS_FAIL,
    state,
    action
  );
}

function getWebhook(state = initialRequestState(), action) {
  return handleRequest(
    WebhookTypes.GET_WEBHOOK_REQUEST,
    WebhookTypes.GET_WEBHOOK_SUCCESS,
    WebhookTypes.GET_WEBHOOK_FAIL,
    state,
    action
  );
}

function createWebhook(state = initialRequestState(), action) {
  return handleRequest(
    WebhookTypes.CREATE_WEBHOOK_REQUEST,
    WebhookTypes.CREATE_WEBHOOK_SUCCESS,
    WebhookTypes.CREATE_WEBHOOK_FAIL,
    state,
    action
  );
}

function updateWebhook(state = initialRequestState(), action) {
  return handleRequest(
    WebhookTypes.UPDATE_WEBHOOK_REQUEST,
    WebhookTypes.UPDATE_WEBHOOK_SUCCESS,
    WebhookTypes.UPDATE_WEBHOOK_FAIL,
    state,
    action
  );
}

function deleteWebhook(state = initialRequestState(), action) {
  return handleRequest(
    WebhookTypes.DELETE_WEBHOOK_REQUEST,
    WebhookTypes.DELETE_WEBHOOK_SUCCESS,
    WebhookTypes.DELETE_WEBHOOK_FAIL,
    state,
    action
  );
}

export default combineReducers({
  getWebhooks,
  getWebhookEvents,
  getWebhook,
  createWebhook,
  updateWebhook,
  deleteWebhook,
});
