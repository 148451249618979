export default {
  SET_FIELD_DATAS: 'SET_FIELD_DATAS',

  GET_FIELD_DATAS_REQUEST: 'GET_FIELD_DATAS_REQUEST',
  GET_FIELD_DATAS_SUCCESS: 'GET_FIELD_DATAS_SUCCESS',
  GET_FIELD_DATAS_FAIL: 'GET_FIELD_DATAS_FAIL',

  GET_SUBMISSION_FIELD_REQUEST: 'GET_SUBMISSION_FIELD_REQUEST',
  GET_SUBMISSION_FIELD_SUCCESS: 'GET_SUBMISSION_FIELD_SUCCESS',
  GET_SUBMISSION_FIELD_FAIL: 'GET_SUBMISSION_FIELD_FAIL',

  GET_FIELD_DATA_REQUEST: 'GET_FIELD_DATA_REQUEST',
  GET_FIELD_DATA_SUCCESS: 'GET_FIELD_DATA_SUCCESS',
  GET_FIELD_DATA_FAIL: 'GET_FIELD_DATA_FAIL',

  UPDATE_FIELD_DATA_REQUEST: 'UPDATE_FIELD_DATA_REQUEST',
  UPDATE_FIELD_DATA_SUCCESS: 'UPDATE_FIELD_DATA_SUCCESS',
  UPDATE_FIELD_DATA_FAIL: 'UPDATE_FIELD_DATA_FAIL',

  UPDATE_FIELD_DATA_NAME_REQUEST: 'UPDATE_FIELD_DATA_NAME_REQUEST',
  UPDATE_FIELD_DATA_NAME_SUCCESS: 'UPDATE_FIELD_DATA_NAME_SUCCESS',
  UPDATE_FIELD_DATA_NAME_FAIL: 'UPDATE_FIELD_DATA_NAME_FAIL',

  MAP_DATAPOINT_FIELD_DATA_REQUEST: 'MAP_DATAPOINT_FIELD_DATA_REQUEST',
  MAP_DATAPOINT_FIELD_DATA_SUCCESS: 'MAP_DATAPOINT_FIELD_DATA_SUCCESS',
  MAP_DATAPOINT_FIELD_DATA_FAIL: 'MAP_DATAPOINT_FIELD_DATA_FAIL',

  ADD_FIELD_ROW_REQUEST: 'ADD_FIELD_ROW_REQUEST',
  ADD_FIELD_ROW_SUCCESS: 'ADD_FIELD_ROW_SUCCESS',
  ADD_FIELD_ROW_FAIL: 'ADD_FIELD_ROW_FAIL',

  ADD_FIELD_COLUMN_REQUEST: 'ADD_FIELD_COLUMN_REQUEST',
  ADD_FIELD_COLUMN_SUCCESS: 'ADD_FIELD_COLUMN_SUCCESS',
  ADD_FIELD_COLUMN_FAIL: 'ADD_FIELD_COLUMN_FAIL',

  DELETE_FIELD_ROW_REQUEST: 'DELETE_FIELD_ROW_REQUEST',
  DELETE_FIELD_ROW_SUCCESS: 'DELETE_FIELD_ROW_SUCCESS',
  DELETE_FIELD_ROW_FAIL: 'DELETE_FIELD_ROW_FAIL',

  DELETE_FIELD_COLUMN_REQUEST: 'DELETE_FIELD_COLUMN_REQUEST',
  DELETE_FIELD_COLUMN_SUCCESS: 'DELETE_FIELD_COLUMN_SUCCESS',
  DELETE_FIELD_COLUMN_FAIL: 'DELETE_FIELD_COLUMN_FAIL',

  VALIDATE_FIELD_DATA_REQUEST: 'VALIDATE_FIELD_DATA_REQUEST',
  VALIDATE_FIELD_DATA_SUCCESS: 'VALIDATE_FIELD_DATA_SUCCESS',
  VALIDATE_FIELD_DATA_FAIL: 'VALIDATE_FIELD_DATA_FAIL',

  VERIFY_FIELD_DATA_REQUEST: 'VERIFY_FIELD_DATA_REQUEST',
  VERIFY_FIELD_DATA_SUCCESS: 'VERIFY_FIELD_DATA_SUCCESS',
  VERIFY_FIELD_DATA_FAIL: 'VERIFY_FIELD_DATA_FAIL',

  REJECT_FIELD_DATA_REQUEST: 'REJECT_FIELD_DATA_REQUEST',
  REJECT_FIELD_DATA_SUCCESS: 'REJECT_FIELD_DATA_SUCCESS',
  REJECT_FIELD_DATA_FAIL: 'REJECT_FIELD_DATA_FAIL',

  ADD_REJECTED_FIELD_DATA: 'ADD_REJECTED_FIELD_DATA',

  CHANGE_FIELD_DATA_VALUE: 'CHANGE_FIELD_DATA_VALUE',

  SET_ACTIVE_FIELD_DATA: 'SET_ACTIVE_FIELD_DATA',
  RESET_VALUES: 'RESET_VALUES',
};
