export default {
  SET_DATAPOINTS: 'SET_DATAPOINTS',

  GET_DATAPOINTS_REQUEST: 'GET_DATAPOINTS_REQUEST',
  GET_DATAPOINTS_SUCCESS: 'GET_DATAPOINTS_SUCCESS',
  GET_DATAPOINTS_FAIL: 'GET_DATAPOINTS_FAIL',

  GET_DATAPOINT_REQUEST: 'GET_DATAPOINT_REQUEST',
  GET_DATAPOINT_SUCCESS: 'GET_DATAPOINT_SUCCESS',
  GET_DATAPOINT_FAIL: 'GET_DATAPOINT_FAIL',

  UPDATE_DATAPOINT_REQUEST: 'UPDATE_DATAPOINT_REQUEST',
  UPDATE_DATAPOINT_SUCCESS: 'UPDATE_DATAPOINT_SUCCESS',
  UPDATE_DATAPOINT_FAIL: 'UPDATE_DATAPOINT_FAIL',

  ADD_ROW_REQUEST: 'ADD_ROW_REQUEST',
  ADD_ROW_SUCCESS: 'ADD_ROW_SUCCESS',
  ADD_ROW_FAIL: 'ADD_ROW_FAIL',

  DELETE_ROW_REQUEST: 'DELETE_ROW_REQUEST',
  DELETE_ROW_SUCCESS: 'DELETE_ROW_SUCCESS',
  DELETE_ROW_FAIL: 'DELETE_ROW_FAIL',

  VALIDATE_DATAPOINT_REQUEST: 'VALIDATE_DATAPOINT_REQUEST',
  VALIDATE_DATAPOINT_SUCCESS: 'VALIDATE_DATAPOINT_SUCCESS',
  VALIDATE_DATAPOINT_FAIL: 'VALIDATE_DATAPOINT_FAIL',

  VERIFY_DATAPOINT_REQUEST: 'VERIFY_DATAPOINT_REQUEST',
  VERIFY_DATAPOINT_SUCCESS: 'VERIFY_DATAPOINT_SUCCESS',
  VERIFY_DATAPOINT_FAIL: 'VERIFY_DATAPOINT_FAIL',

  REJECT_DATAPOINT_REQUEST: 'REJECT_DATAPOINT_REQUEST',
  REJECT_DATAPOINT_SUCCESS: 'REJECT_DATAPOINT_SUCCESS',
  REJECT_DATAPOINT_FAIL: 'REJECT_DATAPOINT_FAIL',

  ADD_REJECTED_DATAPOINT: 'ADD_REJECTED_DATAPOINT',

  CHANGE_DATAPOINT_VALUE: 'CHANGE_DATAPOINT_VALUE',

  RESET_DATAPOINT_VALUES: 'RESET_DATAPOINT_VALUES',

  ADD_COLUMN_REQUEST: 'ADD_COLUMN_REQUEST',
  ADD_COLUMN_SUCCESS: 'ADD_COLUMN_SUCCESS',
  ADD_COLUMN_FAIL: 'ADD_COLUMN_FAIL',

  DELETE_COLUMN_REQUEST: 'DELETE_COLUMN_REQUEST',
  DELETE_COLUMN_SUCCESS: 'DELETE_COLUMN_SUCCESS',
  DELETE_COLUMN_FAIL: 'DELETE_COLUMN_FAIL',
};
