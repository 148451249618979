export default {
  GET_ORDERS_REQUEST: 'GET_ORDERS_REQUEST',
  GET_ORDERS_SUCCESS: 'GET_ORDERS_SUCCESS',
  GET_ORDERS_FAIL: 'GET_ORDERS_FAIL',

  SUPER_ADMIN_GET_ORDERS_REQUEST: 'SUPER_ADMIN_GET_ORDERS_REQUEST',
  SUPER_ADMIN_GET_ORDERS_SUCCESS: 'SUPER_ADMIN_GET_ORDERS_SUCCESS',
  SUPER_ADMIN_GET_ORDERS_FAIL: 'SUPER_ADMIN_GET_ORDERS_FAIL',

  VERIFY_ORDER_REQUEST: 'VERIFY_ORDER_REQUEST',
  VERIFY_ORDER_SUCCESS: 'VERIFY_ORDER_SUCCESS',
  VERIFY_ORDER_FAIL: 'VERIFY_ORDER_FAIL',

  REJECT_ORDER_REQUEST: 'REJECT_ORDER_REQUEST',
  REJECT_ORDER_SUCCESS: 'REJECT_ORDER_SUCCESS',
  REJECT_ORDER_FAIL: 'REJECT_ORDER_FAIL',

  GET_ORDER_REQUEST: 'GET_ORDER_REQUEST',
  GET_ORDER_SUCCESS: 'GET_ORDER_SUCCESS',
  GET_ORDER_FAIL: 'GET_ORDER_FAIL',

  CREATE_ORDER_REQUEST: 'CREATE_ORDER_REQUEST',
  CREATE_ORDER_SUCCESS: 'CREATE_ORDER_SUCCESS',
  CREATE_ORDER_FAIL: 'CREATE_ORDER_FAIL',

  UPDATE_ORDER_REQUEST: 'UPDATE_ORDER_REQUEST',
  UPDATE_ORDER_SUCCESS: 'UPDATE_ORDER_SUCCESS',
  UPDATE_ORDER_FAIL: 'UPDATE_ORDER_FAIL',

  DELETE_ORDER_REQUEST: 'DELETE_ORDER_REQUEST',
  DELETE_ORDER_SUCCESS: 'DELETE_ORDER_SUCCESS',
  DELETE_ORDER_FAIL: 'DELETE_ORDER_FAIL',

  SEARCH_ORDERS_REQUEST: 'SEARCH_ORDERS_REQUEST',
  SEARCH_ORDERS_SUCCESS: 'SEARCH_ORDERS_SUCCESS',
  SEARCH_ORDERS_FAIL: 'SEARCH_ORDERS_FAIL',

  GET_NEWEST_ORDER_REQUEST: 'GET_NEWEST_ORDER_REQUEST',
  GET_NEWEST_ORDER_SUCCESS: 'GET_NEWEST_ORDER_SUCCESS',
  GET_NEWEST_ORDER_FAIL: 'GET_NEWEST_ORDER_FAIL',

  CREATE_MOMO_ORDER_REQUEST: 'CREATE_MOMO_ORDER_REQUEST',
  CREATE_MOMO_ORDER_SUCCESS: 'CREATE_MOMO_ORDER_SUCCESS',
  CREATE_MOMO_ORDER_FAIL: 'CREATE_MOMO_ORDER_FAIL',

  CREATE_VNPAY_ORDER_REQUEST: 'CREATE_VNPAY_ORDER_REQUEST',
  CREATE_VNPAY_ORDER_SUCCESS: 'CREATE_VNPAY_ORDER_SUCCESS',
  CREATE_VNPAY_ORDER_FAIL: 'CREATE_VNPAY_ORDER_FAIL',

  GEN_ORDER_CODE_REQUEST: 'GEN_ORDER_CODE_REQUEST',
  GEN_ORDER_CODE_SUCCESS: 'GEN_ORDER_CODE_SUCCESS',
  GEN_ORDER_CODE_FAIL: 'GEN_ORDER_CODE_FAIL',
}
