import { combineReducers } from 'redux';
import { BillingTypes } from '../../action_types';

import { handleRequest, initialRequestState } from './helpers';

function getUsedCount(state = initialRequestState(), action) {
  return handleRequest(
    BillingTypes.GET_USED_COUNT_REQUEST,
    BillingTypes.GET_USED_COUNT_SUCCESS,
    BillingTypes.GET_USED_COUNT_FAIL,
    state,
    action
  );
}

function getSubmissionTypeCount(state = initialRequestState(), action) {
  return handleRequest(
    BillingTypes.GET_SUBMISSION_TYPE_COUNT_REQUEST,
    BillingTypes.GET_SUBMISSION_TYPE_COUNT_SUCCESS,
    BillingTypes.GET_SUBMISSION_TYPE_COUNT_FAIL,
    state,
    action
  );
}

export default combineReducers({
  getUsedCount,
  getSubmissionTypeCount,
});
