export default {
  GET_API_KEYS_REQUEST: 'GET_API_KEYS_REQUEST',
  GET_API_KEYS_SUCCESS: 'GET_API_KEYS_SUCCESS',
  GET_API_KEYS_FAIL: 'GET_API_KEYS_FAIL',

  GET_API_KEY_REQUEST: 'GET_API_KEY_REQUEST',
  GET_API_KEY_SUCCESS: 'GET_API_KEY_SUCCESS',
  GET_API_KEY_FAIL: 'GET_API_KEY_FAIL',

  UPDATE_API_KEY_REQUEST: 'UPDATE_API_KEY_REQUEST',
  UPDATE_API_KEY_SUCCESS: 'UPDATE_API_KEY_SUCCESS',
  UPDATE_API_KEY_FAIL: 'UPDATE_API_KEY_FAIL',

  CREATE_API_KEY_REQUEST: 'CREATE_API_KEY_REQUEST',
  CREATE_API_KEY_SUCCESS: 'CREATE_API_KEY_SUCCESS',
  CREATE_API_KEY_FAIL: 'CREATE_API_KEY_FAIL',

  DELETE_API_KEY_REQUEST: 'DELETE_API_KEY_REQUEST',
  DELETE_API_KEY_SUCCESS: 'DELETE_API_KEY_SUCCESS',
  DELETE_API_KEY_FAIL: 'DELETE_API_KEY_FAIL',
};
