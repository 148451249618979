import { combineReducers } from 'redux';
import { FolderTypes } from '../../action_types';

import { handleRequest, initialRequestState } from './helpers';

function getFolders(state = initialRequestState(), action) {
  return handleRequest(
    FolderTypes.GET_FOLDERS_REQUEST,
    FolderTypes.GET_FOLDERS_SUCCESS,
    FolderTypes.GET_FOLDERS_FAIL,
    state,
    action
  );
}

function getFavoriteFolders(state = initialRequestState(), action) {
  return handleRequest(
    FolderTypes.GET_FAVORITE_FOLDERS_REQUEST,
    FolderTypes.GET_FAVORITE_FOLDERS_SUCCESS,
    FolderTypes.GET_FAVORITE_FOLDERS_FAIL,
    state,
    action
  );
}

function searchFolders(state = initialRequestState(), action) {
  return handleRequest(
    FolderTypes.SEARCH_FOLDERS_REQUEST,
    FolderTypes.SEARCH_FOLDERS_SUCCESS,
    FolderTypes.SEARCH_FOLDERS_FAIL,
    state,
    action
  );
}

function getFolder(state = initialRequestState(), action) {
  return handleRequest(
    FolderTypes.GET_FOLDER_REQUEST,
    FolderTypes.GET_FOLDER_SUCCESS,
    FolderTypes.GET_FOLDER_FAIL,
    state,
    action
  );
}

function createFolder(state = initialRequestState(), action) {
  return handleRequest(
    FolderTypes.CREATE_FOLDER_REQUEST,
    FolderTypes.CREATE_FOLDER_SUCCESS,
    FolderTypes.CREATE_FOLDER_FAIL,
    state,
    action
  );
}

function updateFolder(state = initialRequestState(), action) {
  return handleRequest(
    FolderTypes.UPDATE_FOLDER_REQUEST,
    FolderTypes.UPDATE_FOLDER_SUCCESS,
    FolderTypes.UPDATE_FOLDER_FAIL,
    state,
    action
  );
}

function deleteFolder(state = initialRequestState(), action) {
  return handleRequest(
    FolderTypes.DELETE_FOLDER_REQUEST,
    FolderTypes.DELETE_FOLDER_SUCCESS,
    FolderTypes.DELETE_FOLDER_FAIL,
    state,
    action
  );
}

function assignFolder(state = initialRequestState(), action) {
  return handleRequest(
    FolderTypes.ASSIGN_FOLDER_REQUEST,
    FolderTypes.ASSIGN_FOLDER_SUCCESS,
    FolderTypes.ASSIGN_FOLDER_FAIL,
    state,
    action
  );
}

function getFolderDocumentTemplates(state = initialRequestState(), action) {
  return handleRequest(
    FolderTypes.GET_FOLDER_DOCUMENT_TEMPLATES_REQUESTS,
    FolderTypes.GET_FOLDER_DOCUMENT_TEMPLATES_SUCCESS,
    FolderTypes.GET_FOLDER_DOCUMENT_TEMPLATES_FAIL,
    state,
    action
  );
}

function updateServiceLabel(state = initialRequestState(), action) {
  return handleRequest(
    FolderTypes.UPDATE_SERVICE_LABEL_REQUEST,
    FolderTypes.UPDATE_SERVICE_LABEL_SUCCESS,
    FolderTypes.UPDATE_SERVICE_LABEL_FAIL,
    state,
    action
  );
}

function createFolderSubmissionField(state = initialRequestState(), action) {
  return handleRequest(
    FolderTypes.CREATE_FOLDER_SUBMISSION_FIELD_REQUEST,
    FolderTypes.CREATE_FOLDER_SUBMISSION_FIELD_SUCCESS,
    FolderTypes.CREATE_FOLDER_SUBMISSION_FIELD_FAIL,
    state,
    action
  );
}

function updateFolderSubmissionField(state = initialRequestState(), action) {
  return handleRequest(
    FolderTypes.UPDATE_FOLDER_SUBMISSION_FIELD_REQUEST,
    FolderTypes.UPDATE_FOLDER_SUBMISSION_FIELD_SUCCESS,
    FolderTypes.UPDATE_FOLDER_SUBMISSION_FIELD_FAIL,
    state,
    action
  );
}

function sortLabels(state = initialRequestState(), action) {
  return handleRequest(
    FolderTypes.SORT_LABELS_REQUEST,
    FolderTypes.SORT_LABELS_SUCCESS,
    FolderTypes.SORT_LABELS_FAIL,
    state,
    action
  );
}

function sortFields(state = initialRequestState(), action) {
  return handleRequest(
    FolderTypes.SORT_FIELDS_REQUEST,
    FolderTypes.SORT_FIELDS_SUCCESS,
    FolderTypes.SORT_FIELDS_FAIL,
    state,
    action
  );
}

export default combineReducers({
  getFolders,
  getFavoriteFolders,
  getFolder,
  createFolder,
  updateFolder,
  deleteFolder,
  assignFolder,
  searchFolders,
  getFolderDocumentTemplates,
  updateServiceLabel,
  createFolderSubmissionField,
  updateFolderSubmissionField,
  sortLabels,
  sortFields,
});
