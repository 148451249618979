export default {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',

  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAIL: 'LOGOUT_FAIL',

  CHECK_EMAIL_REQUEST: 'CHECK_EMAIL_REQUEST',
  CHECK_EMAIL_SUCCESS: 'CHECK_EMAIL_SUCCESS',
  CHECK_EMAIL_FAIL: 'CHECK_EMAIL_FAIL',

  REGISTER_REQUEST: 'REGISTER_REQUEST',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAIL: 'REGISTER_FAIL',

  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAIL: 'RESET_PASSWORD_FAIL',

  GET_ME_REQUEST: 'GET_ME_REQUEST',
  GET_ME_SUCCESS: 'GET_ME_SUCCESS',
  GET_ME_FAIL: 'GET_ME_FAIL',

  CHANGE_ORG_ROLE: 'CHANGE_ORG_ROLE',

  UPDATE_ME_REQUEST: 'UPDATE_ME_REQUEST',
  UPDATE_ME_SUCCESS: 'UPDATE_ME_SUCCESS',
  UPDATE_ME_FAIL: 'UPDATE_ME_FAIL',

  RESET_PASSWORD_CONFIRM_REQUEST: 'RESET_PASSWORD_CONFIRM_REQUEST',
  RESET_PASSWORD_CONFIRM_SUCCESS: 'RESET_PASSWORD_CONFIRM_SUCCESS',
  RESET_PASSWORD_CONFIRM_FAIL: 'RESET_PASSWORD_CONFIRM_FAIL',
};
