export default {
  GET_SUBMISSION_TYPES_REQUEST: 'GET_SUBMISSION_TYPES_REQUEST',
  GET_SUBMISSION_TYPES_SUCCESS: 'GET_SUBMISSION_TYPES_SUCCESS',
  GET_SUBMISSION_TYPES_FAIL: 'GET_SUBMISSION_TYPES_FAIL',

  GET_SUBMISSION_TYPE_REQUEST: 'GET_SUBMISSION_TYPE_REQUEST',
  GET_SUBMISSION_TYPE_SUCCESS: 'GET_SUBMISSION_TYPE_SUCCESS',
  GET_SUBMISSION_TYPE_FAIL: 'GET_SUBMISSION_TYPE_FAIL',

  UPDATE_SUBMISSION_TYPE_REQUEST: 'UPDATE_SUBMISSION_TYPE_REQUEST',
  UPDATE_SUBMISSION_TYPE_SUCCESS: 'UPDATE_SUBMISSION_TYPE_SUCCESS',
  UPDATE_SUBMISSION_TYPE_FAIL: 'UPDATE_SUBMISSION_TYPE_FAIL',

  GET_ORG_SUBMISSION_TYPES_REQUEST: 'GET_ORG_SUBMISSION_TYPES_REQUEST',
  GET_ORG_SUBMISSION_TYPES_SUCCESS: 'GET_ORG_SUBMISSION_TYPES_SUCCESS',
  GET_ORG_SUBMISSION_TYPES_FAIL: 'GET_ORG_SUBMISSION_TYPES_FAIL',

  UPDATE_ORG_SUBMISSION_TYPE_REQUEST: 'UPDATE_ORG_SUBMISSION_TYPE_REQUEST',
  UPDATE_ORG_SUBMISSION_TYPE_SUCCESS: 'UPDATE_ORG_SUBMISSION_TYPE_SUCCESS',
  UPDATE_ORG_SUBMISSION_TYPE_FAIL: 'UPDATE_ORG_SUBMISSION_TYPE_FAIL',

  SUPER_ADMIN_GET_ORG_SUBMISSION_TYPES_REQUEST: 'SUPER_ADMIN_GET_ORG_SUBMISSION_TYPES_REQUEST',
  SUPER_ADMIN_GET_ORG_SUBMISSION_TYPES_SUCCESS: 'SUPER_ADMIN_GET_ORG_SUBMISSION_TYPES_SUCCESS',
  SUPER_ADMIN_GET_ORG_SUBMISSION_TYPES_FAIL: 'SUPER_ADMIN_GET_ORG_SUBMISSION_TYPES_FAIL',

  SUPER_ADMIN_UPDATE_ORG_SUBMISSION_TYPE_REQUEST: 'SUPER_ADMIN_UPDATE_ORG_SUBMISSION_TYPE_REQUEST',
  SUPER_ADMIN_UPDATE_ORG_SUBMISSION_TYPE_SUCCESS: 'SUPER_ADMIN_UPDATE_ORG_SUBMISSION_TYPE_SUCCESS',
  SUPER_ADMIN_UPDATE_ORG_SUBMISSION_TYPE_FAIL: 'SUPER_ADMIN_UPDATE_ORG_SUBMISSION_TYPE_FAIL',

  GET_ICON_SUBMISSION_TYPE_REQUEST: 'GET_ICON_SUBMISSION_TYPE_REQUEST',
  GET_ICON_SUBMISSION_TYPE_SUCCESS: 'GET_ICON_SUBMISSION_TYPE_SUCCESS',
  GET_ICON_SUBMISSION_TYPE_FAIL: 'GET_ICON_SUBMISSION_TYPE_FAIL',

  UPLOAD_ICON_SUBMISSION_TYPE_REQUEST: 'UPLOAD_ICON_SUBMISSION_TYPE_REQUEST',
  UPLOAD_ICON_SUBMISSION_TYPE_SUCCESS: 'UPLOAD_ICON_SUBMISSION_TYPE_SUCCESS',
  UPLOAD_ICON_SUBMISSION_TYPE_FAIL: 'UPLOAD_ICON_SUBMISSION_TYPE_FAIL',
};
