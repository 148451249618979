export default {
  GET_BILLING_POLICIES_REQUEST: 'GET_BILLING_POLICIES_REQUEST',
  GET_BILLING_POLICIES_SUCCESS: 'GET_BILLING_POLICIES_SUCCESS',
  GET_BILLING_POLICIES_FAIL: 'GET_BILLING_POLICIES_FAIL',

  GET_BILLING_POLICY_REQUEST: 'GET_BILLING_POLICY_REQUEST',
  GET_BILLING_POLICY_SUCCESS: 'GET_BILLING_POLICY_SUCCESS',
  GET_BILLING_POLICY_FAIL: 'GET_BILLING_POLICY_FAIL',

  CREATE_BILLING_POLICY_REQUEST: 'CREATE_BILLING_POLICY_REQUEST',
  CREATE_BILLING_POLICY_SUCCESS: 'CREATE_BILLING_POLICY_SUCCESS',
  CREATE_BILLING_POLICY_FAIL: 'CREATE_BILLING_POLICY_FAIL',

  UPDATE_BILLING_POLICY_REQUEST: 'UPDATE_BILLING_POLICY_REQUEST',
  UPDATE_BILLING_POLICY_SUCCESS: 'UPDATE_BILLING_POLICY_SUCCESS',
  UPDATE_BILLING_POLICY_FAIL: 'UPDATE_BILLING_POLICY_FAIL',

  DELETE_BILLING_POLICY_REQUEST: 'DELETE_BILLING_POLICY_REQUEST',
  DELETE_BILLING_POLICY_SUCCESS: 'DELETE_BILLING_POLICY_SUCCESS',
  DELETE_BILLING_POLICY_FAIL: 'DELETE_BILLING_POLICY_FAIL',

  GET_ORG_BILLING_REQUEST: 'GET_ORG_BILLING_REQUEST',
  GET_ORG_BILLING_SUCCESS: 'GET_ORG_BILLING_SUCCESS',
  GET_ORG_BILLING_FAIL: 'GET_ORG_BILLING_FAIL',

  CREATE_CREDIT_REQUEST: 'CREATE_CREDIT_REQUEST',
  CREATE_CREDIT_SUCCESS: 'CREATE_CREDIT_SUCCESS',
  CREATE_CREDIT_FAIL: 'CREATE_CREDIT_FAIL',

  UPDATE_ORG_BILLING_REQUEST: 'UPDATE_ORG_BILLING_REQUEST',
  UPDATE_ORG_BILLING_SUCCESS: 'UPDATE_ORG_BILLING_SUCCESS',
  UPDATE_ORG_BILLING_FAIL: 'UPDATE_ORG_BILLING_FAIL',

  UPDATE_ORG_BILLING_PERIOD_REQUEST: 'UPDATE_ORG_BILLING_PERIOD_REQUEST',
  UPDATE_ORG_BILLING_PERIOD_SUCCESS: 'UPDATE_ORG_BILLING_PERIOD_SUCCESS',
  UPDATE_ORG_BILLING_PERIOD_FAIL: 'UPDATE_ORG_BILLING_FAIL',
};
